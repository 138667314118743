import React from 'react';
import styled from 'styled-components';
import ImageLoader from '../../components/pageloaders/ImageLoader';
// import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";

export const MobileWrapper = styled.div.attrs(props => ({
  className: `vh-100 ${props.className}`,
  id: props.id
}))``;

export const ViewerContainer = styled.div.attrs(props => ({
  className: ''
}))`

display: flex;
@media (max-width: 992px) {
  display: block;
}
`;

export const ViewerDesktopSideBar = styled.div.attrs(props => ({
  className: props.cls
}))`
display: flex;
background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212;
@media (max-width: 992px) {
  display: block;
}
`;


export const RoundedBtn = styled.button.attrs(props => ({
  className: `btn text-center text-center fw-bolder  ${props.className}`
}))`
background: #21212180;
color: #FFFF;
padding: 0 5px !important; 
text-align: center;
font-weight: bolder;
border-radius: 50px;
width: 40px;
height: 40px;
left: calc(50% - 40px/2);
top: calc(50% - 40px/2);
i{
  font-size: 25px !important;
}
`;

export const PillBtn = styled.button.attrs(props => ({
  className: 'btn btn rounded-pill text-center'
}))`
background: ${({ active }) => active ? '#21212180' : 'none'}; 
box-shadow: ${({ active }) => active ? 'auto' : 'none'};
padding:3px 10px;
`;


export const RoundedIconBtn = ({ icon, action, className }) => {
  return (
    <RoundedBtn onClick={action} className={className}>
      <i className={icon}></i>
    </RoundedBtn>
  )
};

export const TopScreenOverlay = styled.div`
position: fixed;
z-index: 100;
top:0;
left: 0;
width: 100%;
justify-content: center;
align-items: center;
margin-top: 7px;
  section{
    position: relative;
    top:50%; 
  };
  @media (max-width: 992px) {
    width: 100%;
  }
`;


export const SwitchBtnOverlay = styled.div`
position: fixed;
z-index: 100;
top:69%;
left: 0;
width: 80%;
justify-content: center;
align-items: center;
margin-top: 7px;
  section{
    position: relative;
    top:50%; 
  };
  @media (max-width: 992px) {
    width: 100%;
  }
`;


const PillBtnLabel = styled.span`
font-family: Roboto;
font-weight: 500;
font-style: normal;
font-size: 14px;
letter: .5%;
text-align: center;
`
export const RoundedPillBtn = ({ icon, action, label, cls, active }) => {
  return (
    <PillBtn onClick={action} className={`${cls}`} active={active}>
      <i className={icon}></i> <PillBtnLabel>{label}</PillBtnLabel>
    </PillBtn>
  )
};

export const ControlBtnOverlay = styled.div`
position: fixed;
z-index: 100;
top:75%;
left: 0;
width: 100%;
justify-content: center;
align-items: center;
margin-top: 7px;
  section{
    position: relative;
    top:50%; 
  };
   @media (max-width: 992px) {
    width: 100%;
  }
`;

const ControlBtnComp = styled.button.attrs(props => ({
  className: 'rounded-circle p-1',
}))`
border-radius: 60%;
background: ${({ bg }) => bg};
padding: 3px 7px; 
outline: none;
border: none;
`;

export const ControlBtn = ({ src, action, bg }) => {
  return (
    <ControlBtnComp onClick={action} bg={bg}>
      <ImageLoader src={src} />
    </ControlBtnComp>
  )
};

// export const PlayerTimeLineOverlay = styled.div`
// position: fixed;
// z-index: 100;
// top:92%;
// // left: 0;
// width: 80%;
// // background: red;
// justify-content: center;
// align-items: center;
// margin-top: 7px;
//   section{
//     position: relative;
//     top:50%; 
//   };
//   @media (max-width: 992px) {
//     width: 100%;
//   }
// `;

// export const PlayerInput = styled.input`
// -webkit-appearance: none;
// height: 5px;
// border-radius: 50px;
// background: rgba(255, 255, 255, 0.418); ;  
// background-image: linear-gradient(90deg,white,white 17%,white 34%,white 51%,white 68%,white 85%,white)!important;
// background-size: ${({ value, max }) => (value / max) * 100}%;
// background-repeat: no-repeat;
// animation: progress-animation 2s linear infinite;

// `
// export const PlayerTimeLine = ({ src, action, bg }) => {
//   return (

//     <MDBContainer className=''>
//       <MDBRow>
//         <PlayerTimeLineOverlay className='row text-white'>
//           <MDBCol className='col-1'>
//             <i class="bi bi-play-fill h3"></i>
//           </MDBCol>
//           <MDBCol className='col-8'>
//             <PlayerInput
//               type="range"
//               name="range"
//               max={10}
//               step="1"
//               value={4}
//               className="col-12"
//               id="range" />
//           </MDBCol>
//           <MDBCol className='col-3 small mt-1'>
//             <small>0:13 / 1:13</small>
//           </MDBCol>
//         </PlayerTimeLineOverlay>

//       </MDBRow>
//     </MDBContainer >

//   )
// };

export const DesktopGesturesWrapper = styled.div.attrs(props => ({
  className: `${props.className}`
}))`
position: absolute;
top: 65px;
right: 360px;
height: 60%;
width: 350px;
overflow: scroll;
scrollbar-width: 2px !important;
// border: 2px solid #21212180;  
// background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212;
::-webkit-scrollbar {
  width: 0px;
}
`
// export const  = styled.div`
// display: ${({ reveal }) => reveal};
// `

export const VideoFrame = styled.video.attrs(props => ({
  // className: props.className
}))`
// z-index: ${({ arView }) => arView ? 1 : -1};
position: absolute;
width: 75%;
// height: 100%;
overflow-y: hidden;
@media (max-width: 768px) {
  width: 100%;
  position: fixed;
  width: 100% !important;
}
`;

export const CapturedPhotoOverLay = styled.div.attrs(props => ({
  className: "text-center"
}))`
  position: fixed;
  z-index: ${({ display }) => display ? 200 : 0};
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #121212;
  color: white;
  display: ${({ display }) => display};
  justify-content: center;
  align-items: center;
  div{
cursor:pointer;
  };
    div>img{
      position: relative; 
    };
`;
