import React, { useState } from 'react';
// eslint-disable-next-line
import { HologramCardWrapper, CardBgWrap, CardImg, CardDescription, HologramDuration, AuthourSection } from './mainPageStyles';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {deleteHologram,renameHologram,toggleArchivePrivacy} from '../Account/AuthenticatedOperations'
import EditableTitle from './EditableTitle';

const logState = state => {
    console.log("Toggled:", state)
}
export const hologramData = [
    {
        hologramImage: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Platform+Assets/Hologram1.png',
        hologramBg: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Platform+Assets/Hologram+thumbnail-BG1.png',
        hologramDuration: '03:14',
        hologramTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu ... ',
        hologramViewCount: 50,
        hologramTimestamp: '2021-12-10T12:00:00.000Z',
        publish: true,
        hologramAuthor:
        {
            authorImg: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Picture.png',
            authorUsername: 'John'

        },
    },
    {
        hologramImage: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Platform+Assets/Hologram3.png',
        hologramBg: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Platform+Assets/Hologram+thumbnail-BG3.png',
        hologramDuration: '03:14',
        hologramTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu ... ',
        hologramViewCount: 201,
        hologramTimestamp: '2021-12-12T12:59-0500',
        publish: true,
        hologramAuthor:
        {
            authorImg: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Picture.png',
            authorUsername: 'Chuks'

        },

    },
    {
        hologramImage: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Platform+Assets/Hologram2.png',
        hologramBg: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Platform+Assets/Hologram+thumbnail-BG2.png',
        hologramDuration: '03:14',
        hologramTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu ... ',
        hologramViewCount: 125,
        hologramTimestamp: '2021-02-11T12:59-0500',
        publish: true,
        hologramAuthor:
        {
            authorImg: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Picture.png',
            authorUsername: 'Ope'

        },
    },
    {
        hologramImage: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Platform+Assets/Hologram2.png',
        hologramBg: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Platform+Assets/Hologram+thumbnail-BG2.png',
        hologramDuration: '03:14',
        hologramTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu ... ',
        hologramViewCount: 125,
        hologramTimestamp: '2021-02-11T12:59-0500',
        publish: false,
        hologramAuthor:
        {
            authorImg: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Picture.png',
            authorUsername: 'Ope'

        },
    },
    {
        hologramImage: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Platform+Assets/Hologram1.png',
        hologramBg: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Platform+Assets/Hologram+thumbnail-BG1.png',
        hologramDuration: '03:14',
        hologramTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu ... ',
        hologramViewCount: 50,
        hologramTimestamp: '2021-12-10T12:00:00.000Z',
        publish: false,
        hologramAuthor:
        {
            authorImg: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Picture.png',
            authorUsername: 'John'

        },
    },
    {
        hologramImage: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Platform+Assets/Hologram3.png',
        hologramBg: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Platform+Assets/Hologram+thumbnail-BG3.png',
        hologramDuration: '03:14',
        hologramTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu ... ',
        hologramViewCount: 201,
        hologramTimestamp: '2021-12-12T12:59-0500',
        publish: true,
        hologramAuthor:
        {
            authorImg: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Picture.png',
            authorUsername: 'Chuks'

        },

    },
]

function Visibility(props) {
    const [isToggled, toggle] = useState(props.toggled)
    React.useEffect(() => {
        toggle(props.toggled)
    }, [props.toggled])

    const callback = () => {
        toggle(!isToggled)
        props.onClick(!isToggled)
    }

    return (
        <div className="col-1 my-auto clickable" onClick={callback}>
            {isToggled?
                <i className="clickable fas fa-eye"></i>
                :
                <i className="clickable fas fa-eye-slash"></i>
            }
        </div>
    )
}

function HologramTitle(props)
{
    const [title, setTitle] = useState(props.currentTitle);
    const [value, setValue] = useState(props.currentTitle);
    const [showInputEle, setShowInputEle] = useState(false);

    React.useEffect(() => {
        setTitle(props.currentTitle);
        if (!showInputEle) setValue(props.currentTitle);
    },[props.currentTitle, showInputEle])

    return (
        <EditableTitle
            value={value}
            initialValue={title}
            handleChange={(e) => setValue(e.target.value)}
            handleDoubleClick={() => setShowInputEle(true)}
            handleBlur={(initialValue) => {setShowInputEle(false);setValue(initialValue)}}
            handleSubmit={() => {setShowInputEle(false);setTitle(value);renameHologram(props.hologramID,value);props.renameFunc(props.idx,value)}}
            showInputEle={showInputEle}
        />     
        )
    }    



function HologramCard({ hologramData,
    hologramRemover,
    changeHologramName,
    updateHologramPrivacy,
    inProfile
}) {
    const [bgImgLoaded, setBgImgLoaded] = React.useState(false);
    const [cardImgLoaded, setCardImgLoaded] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(true);

    
    React.useEffect(() => {
        if (bgImgLoaded && cardImgLoaded) {
            setShowLoader(false);
        }
    }, [bgImgLoaded, cardImgLoaded])

    
    return (
        <>
            <HologramCardWrapper>
                <div className="row mt-5">
                    {hologramData.length > 0 ? hologramData.map((item, i) => (
                        <>
                            <div className={showLoader ? 'd-none' : 'col-md-4 my-3 itemcard d-block'} key={i} id={`itemcard${i}`}>
                                <Link to={'/viewer?archive_id='+item.hologramID} className="no-decor">
                                    <CardBgWrap className={`mx-auto text-center hologramBg hologramBg${i}`}
                                        id={`hologramBg${i}`}
                                        hologramBg={item.hologramBg}
                                        onLoad={() => setCardImgLoaded(true)}
                                    >
                                        <img
                                            src={item.hologramImage}
                                            onLoad={() => setBgImgLoaded(true)}
                                            alt=""
                                            className="img-fluid col-8"
                                            style={{ position: 'relative', top: -45 }} />
                                    </CardBgWrap>
                                </Link>
                                <HologramDuration className="col-4 ms-auto text-end">
                                    <span className="bg-dark text-white px-2 me-1 badge badge">{item.hologramDuration}</span>
                                </HologramDuration>
                                <CardDescription className="row mx-0">
                                    {inProfile?
                                        <div style={{justifyContent: 'space-between'}} className='titleContainer'>
                                            <HologramTitle hologramID={hologramData[i].hologramID} currentTitle={item.hologramTitle} idx={i} renameFunc={changeHologramName}/>
                                            <Visibility
                                                label="Public"
                                                toggled={item.privacy}
                                                onClick={()=>{updateHologramPrivacy(i);toggleArchivePrivacy(hologramData[i].hologramID)}}
                                            />
                                            <div className="col-1 my-auto clickable" onClick={()=>{
                                                if (window.confirm('Are you sure you want to delete this recording?')) {
                                                    deleteHologram(hologramData[i].hologramID);hologramRemover(i)
                                                    console.log('Hologram deleted.');
                                                } else {
                                                    console.log('Canceled deletion.');
                                                }
                                                }}>
                                                <i style={{float: 'right'}} className="far fa-trash-alt" aria-hidden="true"></i>
                                            </div>   
                                        </div>
                                        :
                                        <>
                                            <div className="col-11 p-0 py-0 mx-0">
                                                <span className=""> {item.hologramTitle}</span>
                                            </div>                                          
                                            <div className="col-1 my-auto clickable">
                                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                            </div>
                                        </>
                                    }
                                </CardDescription>
                                <AuthourSection className=''>
                                    <li className="my-auto mx-1">
                                        <Link to={'/profile'} className="no-decor text-white">
                                            <img src={item.hologramAuthor.authorImg} alt="" className=""
                                                style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                                        </Link>
                                    </li>
                                    <li className="my-auto mx-1 mt-1">
                                        <Link to={'/profile'} className="no-decor text-white">{item.hologramAuthor.authorUsername}</Link>
                                    </li>
                                    <li className="my-auto mx-1 mt-1">
                                        <span className="">• {item.hologramViewCount} Views
                                        </span>
                                    </li>
                                    <li className="my-auto mx-1 mt-1">
                                        <span className="">
                                            • {moment(item.hologramTimestamp).format('DD MMM, YYYY')}
                                        </span>
                                    </li>
                                </AuthourSection>
                            </div>

                            {/* // : */}
                            {/* <div className={showLoader ? `col-md-4 mx-auto text-center p-3 position-absoute` : 'd-none'} id={`itemcard_loader${i}`}>
                                <div className="my-3 shadow bg-secondary p-4 rounded">
                                    <i className="spinner-grow text-white"></i>
                                </div>
                            </div> */}
                            {/* // } */}
                        </>

                    )) : ""}
                    {/* */}
                </div>
            </HologramCardWrapper>

        </>
    )
}

export default HologramCard
