import React from 'react';
import CategorySec from './CategorySec';
import MainBaseNav from './MainBaseNav';
import MainTopNav from './MainTopNav';
import {DestopSideNav} from './MainBaseNav';

import HologramCard, { hologramData } from './HologramCard';
import { LazyLoader, LazyLoader2 } from '../../components/pageloaders/LazyLoader';
import { toast } from "react-toastify";


export const openBaseTab = (e, tabName) => {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("menu-tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("menu-tablinks");
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    e.currentTarget.className += " active";
};

function MainPage() {
    const [offLazyLoader, setOffLazyLoader] = React.useState(true)
    const toggleNavCatTab = (e, category) => {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("cat-tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.color = "white";
            tabcontent[i].style.border = "solid 1px white";
        }
        tablinks = document.getElementsByClassName("cat-tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        e.currentTarget.className += " active";
    }
    const comingSoon = () => {
        toast.dark('Avaliable in a bit please check back soon 😇 ', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    React.useEffect(() => {
        document.getElementsByTagName("body")[0].style.background = 'black'
        let stateCheck = setInterval(() => {
            if (document.readyState === 'complete') {
                clearInterval(stateCheck);
                setOffLazyLoader(false)
            }
        }, 1000);
    }, [])

    return (
        <>

            <div className="d-lg-none d-block">
                {/* <LazyLoader display={offLazyLoader} /> */}
                <div className="bg-dark">
                    <MainTopNav />
                    <div id="1" className="menu-tabcontent" style={{ display: 'block' }}>
                        <CategorySec toggleNavCatTab={toggleNavCatTab} />
                        <HologramCard
                            hologramData={hologramData}
                            inProfile={false}
                        />
                    </div>
                    <div id="2" className="menu-tabcontent" >
                        <p className="text-white text-center my-auto pt-5 mt-5">
                            <img src="https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Holotch+logo.png" alt="" className="ing-fluid" />
                            {/* <br />                            Following coming soon <br /> Stay Tunded! */}
                        </p>
                    </div>
                    <div id="3" className="menu-tabcontent" >
                        <p className="text-white text-center my-auto pt-5 mt-5">
                            <img src="https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Holotch+logo.png" alt="" className="ing-fluid" />
                            {/* <br />                            Bookmarks coming soon <br /> Stay Tunded! */}
                        </p>
                    </div>
                    <MainBaseNav
                        openBaseTab={openBaseTab}
                    />
                </div>
            </div>

            {/* Desktop */}
            <div className="container-fluid d-lg-block d-none">
                <div className="row">
                    <div className="col-lg-12 mb-5">
                        <MainTopNav />
                    </div>
                    <div className="col-lg-1 mt-5 vh-100 me-auto shadow position-fixed" style={{ background: "linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212" }}>
                        <DestopSideNav
                            openBaseTab={openBaseTab}
                        />
                    </div>
                    <div className="col-lg-11 ms-auto">
                        <LazyLoader2 display={offLazyLoader} />
                        <div className="bg-dark">
                            <div id="Discover2" className="menu-tabcontent" style={{ display: 'block', background: 'black' }}>
                                <CategorySec toggleNavCatTab={toggleNavCatTab} />
                                <HologramCard
                                    hologramData={hologramData}
                                    inProfile={false}
                                />
                            </div>
                            <div id="Following2" className="menu-tabcontent" >
                                <p className="text-white text-center my-auto pt-5 mt-5">
                                    <img src="https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Holotch+logo.png" alt="" className="ing-fluid" />
                                    <br />                            Following coming soon <br /> Stay Tunded!
                                </p>
                            </div>
                            <div id="Bookmarks2" className="menu-tabcontent" >
                                <p className="text-white text-center my-auto pt-5 mt-5">
                                    <img src="https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Holotch+logo.png" alt="" className="ing-fluid" />
                                    <br />                            Bookmarks coming soon <br /> Stay Tunded!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default MainPage;
