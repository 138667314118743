import React, { useRef, useEffect, useMemo, useState, useContext, useCallback } from "react"
import { connect } from "react-redux";
import SectionTwo from '../sections/SectionTwo';
import SectionOne from '../sections/SectionOne';
import SectionThree from '../sections/SectionThree';
import DesktopDetailSection from '../../components/detailSection/DesktopDetailSection';
import PlayerTimeLine, { volumeMuted } from '../../components/playertimeline';
import * as THREE from "three";
import {checkArchiveAccess} from "../Account/AuthenticatedOperations"
import {
    ViewerContainer,
    ViewerDesktopSideBar,
    MobileWrapper,
    CapturedPhotoOverLay,
} from '../utils/hologramui.style';
import {
    togglePlayPause,
    restartPlayback,
    setMetadata,
    incrementTimelinePos,
    updateStatus,
    forward2Secs,
    changeTimeLinePos,
    pageLoaderCheck,
} from '../../components/holotchViewer/hologram/hologram.helpers';
import {
    switchViewerMode,
    popMenu,
    popSettingsMobile,
    popDetailsMobile,

} from '../utils/hologramUI.redux';
import {
    PlayerGestures,
    PlayerGesturesDesktop,
    startRecording,
    capturePhoto,
    savePhoto,
    handleShare,
    secondsToMinutes,
    iosExpandHandler
} from '../../components/holotchViewer/hologram/hologramUi.helpers';
import BaseEnvironment from '../../components/holotchViewer/VRViewer/Environment';
import { Canvas, useThree } from "@react-three/fiber"
import OrbitControls from "../CamControls";
import { softShadows } from '@react-three/drei'
import ReadArchive from '../../components/holotchViewer/VRViewer/ArchiveS3fs/ArchivePlayer';
import FrameLoader from '../../components/pageloaders/FrameLoader';
import PageLoader from '../../components/pageloaders/mainpageloader';
import SharingContent from '../../components/SharingContent';
import ImageLoader from '../../components/pageloaders/ImageLoader';
import QRCodePage from '../utils/QRCodePage'; import { isMobile } from 'react-device-detect';
import { startAR, animate, begin, placer, arBtnClick } from '../ARLogic';
import { refreshSession } from '../Account/SessionManagement';
softShadows();
var controls
var setupMouseCallback=false
const CameraController = () => {
    const { camera, gl } = useThree();
    React.useEffect(
        () => {
            controls = new OrbitControls(camera, gl.domElement);
            controls.minDistance = 3;
            controls.maxDistance = 30;
            controls.maxPolarAngle = Math.PI / 2
            return () => {
                controls.dispose();
            };
        },
        [camera, gl]
    );
    return null;
};


export const playStatus = {
    PLAYING: 0,
    PAUSED: 1,
    BUFFERING: 2,
    FINISHED: 3,
}




function HologramUi({ switchViewerMode, popMenu, popSettingsMobile, popDetailsMobile, viewerModeProps, menuProps, ...props }) {
    const { captureBtn } = viewerModeProps;
    /*
  HOLOGRAM SECTION LOGIC HANDLERS
   */
    const setArPath = (window.location.pathname === '/viewer-ar' ? true : false)
    const [status, setStatus] = React.useState(playStatus.PLAYING)
    const [timelinePos, setTimelinePos] = React.useState(0)
    const [hologramLength, setHologramLength] = React.useState(0)
    const [framerate, setFramerate] = React.useState(0)
    const [checkLoader, setCheckLoader] = React.useState(true)
    const [arView, setARView] = React.useState(false)
    const [cameraCapture, setCameraCapture] = React.useState(false)
    const [capturedPhoto, setCapturedPhoto] = React.useState('')
    const [recordActive, setRecordActive] = React.useState(false)
    const [getLike, setGetLike] = React.useState(false)
    const [archiveId, setArchiveId] = React.useState('')
    const [videoRecTimer, setVideoRecTimer] = React.useState('00:00')
    const [recTimerSec, setRecTimerSec] = React.useState('none')
    const [iosViewer, setIosViewer] = React.useState(false)
    const [iosExpand, setIosExpand] = React.useState(true)
    const [startStreaming, setStartStreaming] = React.useState(false)
    const [arRef, setArRef] = React.useState([])
    const [isAccessible, setAccess] = React.useState(false)
    const [checkedAccess, setChecked] = React.useState(false)
    const [hovered, setHovered] = React.useState([])
    const refElement = React.useRef()
    const [aHeld, setAHeld] = React.useState(false);
    const [dHeld, setDHeld] = React.useState(false);

    function ClickListener(evt,camera,scene,fromMouse){
        //evt.preventDefault();
        //console.log(evt)
        if (evt.buttons==2||!fromMouse){    
            var vec = new THREE.Vector3();
            if (fromMouse){
                vec.set(
                    ( evt.clientX / window.innerWidth ) * 2 - 1,
                    - ( evt.clientY / window.innerHeight ) * 2 + 1,
                    0.5 );
            }else{
                if (evt.touches.length!=1)return;
                vec.set(
                    ( evt.touches[0].clientX / window.innerWidth ) * 2 - 1,
                    - ( evt.touches[0].clientY / window.innerHeight ) * 2 + 1,
                    0.5 );
            }
            var raycaster = new THREE.Raycaster();

            raycaster.setFromCamera(vec,camera)
            const intersects=raycaster.intersectObjects(scene.children)
            //console.log(intersects)
            for (let i=0;i<intersects.length;i++){
                if (intersects[i].object.name=="floor")handleClickTranslation(intersects[i].point)
            }
            //console.log(intersects)
        }
    }


    function GetClicked3DPoint(){
        const {camera,scene}=useThree()
        React.useEffect(() => {
            if (!setupMouseCallback){
                window.addEventListener('mousedown', (e)=>{ClickListener(e,camera,scene,true)})
                window.addEventListener('touchstart', (e)=>{ClickListener(e,camera,scene,false)})
                setupMouseCallback=true
                return () => {
                    window.removeEventListener('mousedown', (e)=>{ClickListener(e,camera,scene,true)});
                    window.removeEventListener('touchstart', (e)=>{ClickListener(e,camera,scene,false)});
                };
            }
        }, [])
      return <></>
    }
    function downHandler(e) {
      if (e.keyCode === 65) {
        setAHeld(true);
      }
      if (e.keyCode === 68) {
        setDHeld(true);
      }
    }
  
    function upHandler(e) {
      if (e.keyCode === 65) {
        setAHeld(false);
      }
      if (e.keyCode === 68) {
        setDHeld(false);
      }
    }
    const playerProps = {
        status,
        timelinePos,
        hologramLength,
        framerate,
        checkLoader
    };

    function handleClickTranslation(pos){
        controls.translateMeshes(pos)
    }

    function initMeshInControlled(angle,scale,position){
        controls.meshRotationY.push(angle)
        controls.meshScale.push(scale)
        controls.meshPos.push(position)
    }
    function addMeshToControlled(i){
        controls.meshesControlled.push(i)
    }
    function removeMeshFromControlled(i){
        controls.meshesControlled=controls.meshesControlled.filter(e => e !== i)
    }
    React.useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        return () => {
          window.removeEventListener('keydown', downHandler);
          window.removeEventListener('keyup', upHandler);
        };
    }, [])
    React.useEffect(() => {
        if (setArPath) {
            startAR(); animate();
        }
    }, [setArPath])

    React.useEffect(() => {
        async function fetchData(id) {
            var success=true
          var res=await checkArchiveAccess(id).then(res=>{return res}).catch(err => {success=false;console.log(err)})
          if (success)setAccess(res.status===200)
          setChecked(true)
        }
        
        const q_params = new URLSearchParams(props.location.search);
        var id=q_params.get('archive_id')
        setArchiveId(id);
        if (id !== '') {
            setIosViewer(true)
        }
        fetchData(id);
    },[props.location.search]);

    React.useEffect(() => {
        if (isMobile && setArPath) {
            let arBtnId = document.getElementById("ARButton");
            if (arBtnId && arRef && arRef.current && checkLoader) {
                begin(arRef, false);
                arBtnId.style.backgroundColor = "#7c1eef";
                arBtnId.style.display = "block";
                arBtnId.addEventListener("click", function () {
                    arBtnClick();
                })
            } else if (arBtnId && arRef == null) {
                arBtnId.style.display = "none";
            }
        }
        // eslint-disable-next-line
    }, [arRef && arRef.current ? arRef : null])
    React.useEffect(() => document.body.style.background = 'black', [])
    return (
        <>
        {checkedAccess?
            <>
                {isAccessible?
                    <>
                    {!isMobile && setArPath ? <QRCodePage />:<>
                        <PageLoader archivePreLoader={true} mainLoader={false} viewerLoader={checkLoader} setStartStreaming={setStartStreaming} />

                        <ViewerContainer id="mainScreen2">
                            <div className="col-md-12">
                                {/* Top Screen Floating button components  */}
                                <SectionOne
                                    popSettingsMobile={popSettingsMobile}
                                    popDetailsMobile={popDetailsMobile}
                                    arView={arView}
                                    startStreaming={startStreaming}
                                    restartStream={null}
                                    videoRecTimer={videoRecTimer}
                                    recTimerSec={recTimerSec}
                                    iosViewer={iosViewer}
                                    iosExpand={iosExpand}
                                />

                                <PlayerGesturesDesktop
                                    popMenu={popMenu}
                                    menuProps={menuProps}
                                    popDetailsMobile={popDetailsMobile}
                                    popSettingsMobile={popSettingsMobile}

                                />

                                <SharingContent
                                    handleShare={handleShare}
                                    getLike={getLike}
                                    setGetLike={setGetLike}
                                    iosViewer={iosViewer}
                                />
                                <CapturedPhotoOverLay display={cameraCapture ? 'block' : 'none'}>
                                    <div className="row mx-1">
                                        <div className="col-12 card-header" style={{ background: "linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212" }}>
                                            <div className="row">
                                                <div className="col-6 text-start" onClick={() => setCameraCapture(false)}>
                                                    <i className="bi bi-x fs-2"></i> <span className="">&ensp; Photo capture</span>
                                                </div>
                                                <div className="col-6 text-end" onClick={() => setCameraCapture(false)}>
                                                    <i className="bi bi-trash fs-4"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-10 col-lg-7 mx-auto">
                                            <ImageLoader
                                                cls={`img-fluid captureOverlay `}
                                                spinnerSize={15}
                                                src={capturedPhoto}
                                            />
                                        </div>
                                        <div className="col-12 card-header pb-4" style={{ background: "linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212" }}>
                                            <div className="row">
                                                <div className="col-7 text-start mx-lg-auto mb-2" onClick={() => handleShare('')}>
                                                    <i className="fa fa-share"></i> <span className="">&ensp; Share</span>
                                                </div>
                                                <div className="col-7 text-start mx-lg-auto" onClick={() => savePhoto(capturedPhoto, setCameraCapture)}>
                                                    <i className="bi bi-save"></i> <span className="">&ensp; Save to photos</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CapturedPhotoOverLay>
                                <MobileWrapper id={setArPath ? "container" : ''}>
                                    <Canvas
                                        linear shadows
                                        mode="concurrent"
                                        camera={{ fov: 75 }}
                                        id='hologram__section'
                                        className={setArPath ? "ar__canvas" : ''}
                                        ref={refElement}
                                        gl={{
                                            preserveDrawingBuffer: true,
                                        }}>
                                        <GetClicked3DPoint/>
                                        <CameraController />
                                        <directionalLight castShadow position={[0, 10, 0]} color='#FFC08A' intensity={0.3}
                                            shadow-mapSize-width={1024}
                                            shadow-mapSize-height={1024}
                                            shadow-camera-far={50}
                                            shadow-camera-left={-10}
                                            shadow-camera-right={10}
                                            shadow-camera-top={10}
                                            shadow-camera-bottom={-10}
                                        />
                                        {setArPath ? <></> : <BaseEnvironment  handleClickTranslation={handleClickTranslation}/>}
                                        <ReadArchive
                                            isMuted={volumeMuted}
                                            pageLoaderCheck={pageLoaderCheck}
                                            setCheckLoader={setCheckLoader}
                                            setMetadata={setMetadata}
                                            setHologramLength={setHologramLength}
                                            setFramerate={setFramerate}
                                            incrementTimelinePos={incrementTimelinePos}
                                            setTimelinePos={setTimelinePos}
                                            restartPlayback={restartPlayback}
                                            updateStatus={updateStatus}
                                            setStatus={setStatus}
                                            forward2Secs={forward2Secs}
                                            changeTimeLinePos={changeTimeLinePos}
                                            setArRef={setArRef}
                                            playerState={playerProps}
                                            timelinePos={timelinePos}
                                            hologramLength={hologramLength}
                                            framerate={framerate}
                                            status={status}
                                            getMsg={null}
                                            archiveId={archiveId}
                                            rotateLeft={dHeld}
                                            rotateRight={aHeld}
                                            controls={controls}
                                            amtc={addMeshToControlled}
                                            rmfc={removeMeshFromControlled}
                                            imic={initMeshInControlled}
                                        />
                                    </Canvas>
                                    {setArPath ? <button type="button" className="btn btn-sm btn-outline-white" id="place-button" onClick={placer}>Tap</button> : null}
                                    <FrameLoader loaded={checkLoader} setIndex={true} />
                                </MobileWrapper>

                                <div className="d-lg-none d-block">
                                    <PlayerGestures
                                        popMenu={popMenu}
                                        menuProps={menuProps}
                                        popDetailsMobile={popDetailsMobile}
                                        popSettingsMobile={popSettingsMobile}
                                    />
                                </div>
                                {/* Video and Photo switch components  */}
                                <SectionTwo
                                    switchViewerMode={switchViewerMode}
                                    viewerModeProps={viewerModeProps}
                                    recordActive={recordActive}
                                    iosViewer={iosViewer}
                                />
                                {/* AR/VR Switch component  */}
                                <SectionThree
                                    captureBtn={captureBtn}
                                    refElement={refElement}
                                    recordActive={recordActive}
                                    arView={arView}
                                    iosViewer={iosViewer}
                                    archiveId={archiveId}
                                    iosExpand={iosExpand}
                                    viewerModeProps={viewerModeProps}
                                    capturePhoto={capturePhoto}
                                    startRecording={startRecording}
                                    setRecordActive={setRecordActive}
                                    setCapturedPhoto={setCapturedPhoto}
                                    setCameraCapture={setCameraCapture}
                                    secondsToMinutes={secondsToMinutes}
                                    setVideoRecTimer={setVideoRecTimer}
                                    setRecTimerSec={setRecTimerSec}
                                    handleShare={handleShare}
                                />
                                {/* Time line component*/}
                                <PlayerTimeLine
                                    maxVal={hologramLength}
                                    currentVal={timelinePos}
                                    changeTimeLinePos={changeTimeLinePos}
                                    setTimelinePos={setTimelinePos}
                                    totalTime={new Date((hologramLength && framerate > 0 ? hologramLength / framerate : 0) * 1000).toISOString().substr(11, 8)}
                                    currentTime={new Date((timelinePos ? timelinePos / framerate : 0) * 1000).toISOString().substr(11, 8)}
                                    playStatus={playStatus}
                                    togglePlayPause={togglePlayPause}
                                    status={status}
                                    setStatus={setStatus}
                                    restartPlayback={restartPlayback}
                                    iosViewer={iosViewer}
                                    iosExpand={iosExpand}
                                    setIosExpand={setIosExpand}
                                    iosExpandHandler={iosExpandHandler}
                                />
                            </div>
                            <ViewerDesktopSideBar cls='col-lg-3 d-none'>
                                <DesktopDetailSection />
                            </ViewerDesktopSideBar>
                        </ViewerContainer >
                    </> }
                    </>
                    :
                    <div className="container-fluid text-center mb-auto text-white mt-5 mt-lg-0" style={{ paddingTop: '20%' }}>
                        <div className="fs-6 pt-0 mt-0 mb-5">
                            <ImageLoader
                                src="https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Recolor+-+Hands+Tiny+Unlock.png"
                                cls={'img-fluid'}
                            />
                        </div>
                        <div className="login-form">
                            <div>Archive not found...</div>
                        </div>
                    </div>
                }
            </>
            :
            <PageLoader archivePreLoader={true} mainLoader={false} viewerLoader={checkLoader} setStartStreaming={setStartStreaming} />
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    viewerModeProps: state.hologramUIReducer,
    menuProps: state.popMenuReducer,
});

export default connect(mapStateToProps, {
    switchViewerMode,
    popMenu,
    popSettingsMobile,
    popDetailsMobile
})(HologramUi);
