import React from 'react';
import { MainPageCatWrapper } from './mainPageStyles';

function CategorySec({ toggleNavCatTab }) {
    const catData = [
        {
            category: 'All',
            col: 2
        },
        {
            category: 'Trending',
            col: 3
        },
        {
            category: 'Recent',
            col: 2
        },
        {
            category: 'Live',
            col: 2
        },
        {
            category: 'For you',
            col: 3
        },
    ]
    return (
        <>
            <MainPageCatWrapper>
                <div className="d-lg-none d-block">
                    <h1 className="fs-4 text-white py-3">
                        <span className="">Discover</span>
                    </h1>
                    <div className="row text-center cat-tab">
                        {catData.map((cat, i) => (
                            <div className={`col-${cat.col} px-1 cat-tablinks ${i === 0 ? 'active' : ''}`} key={i} onClick={(e) => toggleNavCatTab(e, cat.category.slice(0, 3))}>
                                <p className={`make-cat-pointer small text-white rounded-pill shadow p-1 border cat-tabcontent bg-dark`}
                                    id={cat.category.slice(0, 3)}>
                                    <span>{cat.category}</span>
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                {/* desktop */}
                <div className="d-lg-block d-none border-bottom border-white">
                    <div className="row">
                        <h1 className="fs-4 text-white py-3 col-3 me-auto text-start">
                            <span className="">Discover</span>
                        </h1>
                        <div className="col-6 ms-auto text-end">

                            <div className="row text-center cat-tab">
                                {catData.map((cat, i) => (
                                    <div className={`col-${cat.col} px-1 cat-tablinks ${i === 0 ? 'active' : ''}`} key={i} onClick={(e) => toggleNavCatTab(e, cat.category.slice(0, 3))}>
                                        <p className={`make-cat-pointer small text-white rounded-pill shadow p-1 border cat-tabcontent bg-dark`}
                                            id={cat.category.slice(0, 3)}>
                                            <span>{cat.category}</span>
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </MainPageCatWrapper>
        </>
    )
}

export default CategorySec;
