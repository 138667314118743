import React from 'react';
import { playStatus } from '../hologram/hologramUi.helpers';
import * as THREE from 'three';
import * as B64 from 'base64-arraybuffer';
var AudioFeeder = require('audio-feeder');
var feeder = new AudioFeeder();
var bufferedAudioData=[]
var initializedAudio=false
var currIdx = 0
var started = false
var prevKeyFrame = 0
// eslint-disable-next-line
var playingFrames = false
// eslint-disable-next-line
var resetBuffer = false
var frameBuffer = []
var mainPlaybackBuffer = []
var meshRefs= [] //references to the meshes in currMeshList

function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}


function getGeometry(encodedGeom) {
    if (encodedGeom !== null) {
        var decoder = new TextDecoder("utf-8");
        var view = new DataView(encodedGeom, 0, encodedGeom.byteLength);
        var string = decoder.decode(view);
        var geometry = JSON.parse(string);
        return geometry
    } else {
        return null
    }

}

function getSortedFrames(batch) {
    const ordered = Object.keys(batch).sort().reduce(
        (obj, key) => {
            obj[key] = batch[key];
            return obj;
        },
        {}
    );
    var frameArray = []
    Object.keys(ordered).forEach(key => {
        frameArray.push(ordered[key])
    });
    return frameArray
}

const vShader=
    /*glsl*/ `
  uniform float size;
  
  void main() {
    vec4 pos = modelViewMatrix * vec4( position + normal * size, 1.0 );
    gl_Position = projectionMatrix * pos;
  }
  `
  const fShader=
    /*glsl*/ `
  uniform vec3 color;
  
  void main() {
    gl_FragColor = vec4(color, 1.);
  }
  `
function changeMat(ref,outRef,idx){
    meshRefs[idx].hovered=!meshRefs[idx].hovered
    if (!meshRefs[idx].clicked){
        if (!meshRefs[idx].hovered){
            outRef.current.geometry=new THREE.BufferGeometry()
        }else{
            var uniforms= {
                size: { value: 40.0 },
                color: new THREE.Uniform( new THREE.Vector3(0.8, 0.8, 0.01) )
            };

            var mat=new THREE.ShaderMaterial({
                vertexShader: vShader,
                fragmentShader: fShader,
                uniforms
            });
            mat.side=THREE.BackSide
            mat.depthWrite=false
            outRef.current.material= mat
            outRef.current.geometry = ref.current.geometry
        }
    }
}

function handleClick(ref,outRef,idx,cancel,amtc,rmfc){
    if (cancel){
        meshRefs[idx].clicked=false
        outRef.current.geometry=new THREE.BufferGeometry()
        rmfc(idx)
    }else{
        meshRefs[idx].clicked=!meshRefs[idx].clicked
        if (!meshRefs[idx].clicked){
            rmfc(idx)
            outRef.current.geometry=new THREE.BufferGeometry()
        }else{
            var uniforms= {
                size: { value: 20.0 },
                color: new THREE.Uniform( new THREE.Vector3(0.588, 0.329, 1.0) )
            };
            amtc(idx,ref.current.rotation.y)
            var mat=new THREE.ShaderMaterial({
                vertexShader: vShader,
                fragmentShader: fShader,
                uniforms
            });
            mat.side=THREE.BackSide
            mat.depthWrite=false
            outRef.current.material= mat
            outRef.current.geometry = ref.current.geometry
        }
    }
}

function BuildMesh(idx, ref,refOut,amtc,rmfc,imic) {
    const scale=0.007
    const angle=Math.PI
    const position=new THREE.Vector3(2, -10, -15)
    imic(angle,scale,position)
    return (
        <group key={idx}>
            <mesh onPointerMissed={(e)=>{if(e.button==0&&e.pointerType=="mouse")handleClick(ref,refOut,idx,true,amtc,rmfc)}} onClick={(e)=> handleClick(ref,refOut,idx,false,amtc,rmfc)} onPointerLeave={(e) => changeMat(ref,refOut,idx)} onPointerOver={(e) => changeMat(ref,refOut,idx)} ref={ref} castShadow={true} receiveShadow={false} scale={scale} rotation-y={angle} position={[2, -10, -15]}>

                <bufferGeometry
                    attach="geometry"
                    onUpdate={self => self.computeVertexNormals()}
                >

                <meshBasicMaterial attach='material' side={THREE.DoubleSide} color={'yellow'} />

                </bufferGeometry>

            </mesh>
            <mesh ref={refOut}  castShadow={true} receiveShadow={false} scale={0.007} rotation-y={Math.PI} position={[2, -10, -15]}>

                <bufferGeometry
                    attach="geometry"
                    onUpdate={self => self.computeVertexNormals()}
                >

                <meshBasicMaterial attach='material' side={THREE.DoubleSide} color={'yellow'} />

                </bufferGeometry>

            </mesh>
        </group>
        )
}

class ReadArchive extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            meshNum: 1,
            batchSize: 30,
            framePos: 0,
            frameCount: 0,
            dracoEncoding: true,
            startAtMostRecent: false,
            requesting: false, //whether we're currently requesting from S3
            loaded: false, //whether the first frame has been loaded
            readerWorker: null, //web worker for parsing mesh data
            onGoingReaderRequests: new Set(),//keep track of ongoing reader worker jobs
            nextFrame: null, //next frame in the frameBuffer          
            currMeshList: [], //meshes to be rendered
            frameQueue: {}, //accumulates frames from the batch currently being processed
            latestBatch: 0, //batch being currently fetched
            lengthInBatches: 0, //recording length in batches
            SQSparams: {},
            batchWokerReady: false, //whether the batch worker is ready
            getLiveFrame: '', //get the live frame from the server
        };
        this.receivedReaderWorkerMessage = this.receivedReaderWorkerMessage.bind(this)
        this.updateBufferGeometry = this.updateBufferGeometry.bind(this)
        this.sendBatchToWorker = this.sendBatchToWorker.bind(this)
        this.startBufferTimer = this.startBufferTimer.bind(this)
        this.keyboardControls= this.keyboardControls.bind(this)
        this.playFrames = this.playFrames.bind(this)
    }


    //update each mesh geometry buffer using references
    updateBufferGeometry() {
        var frameToRender = this.state.nextFrame
        for (let i = 0; i < this.state.meshNum; i++) {
            if (frameToRender[i] !== undefined && frameToRender[i].textureData !== undefined) {
                //get mesh and its reference
                var ref = meshRefs[i].ref
                var mesh = frameToRender[i]
                //get decoder output texture data 
                var texData = new Uint8Array(frameToRender[i].textureData.buf)
                var height = frameToRender[i].textureData.height
                var width = frameToRender[i].textureData.width
                var vertices, triangles, uvs
                if (this.state.dracoEncoding) {
                    if (mesh.geometry != null) {
                        vertices = new Float32Array(Object.values(mesh.geometry.attributes[0].array))
                        uvs = new Float32Array(Object.values(mesh.geometry.attributes[1].array))
                        triangles = new Uint16Array(Object.values(mesh.geometry.index.array))
                    } else {
                        continue
                    }
                } else {
                    vertices = mesh.geometry.vertices
                    uvs = new Float32Array(Object.values(mesh.geometry.uvs))
                    triangles = mesh.geometry.faces
                }
                console.log(width)
                const texture = new THREE.DataTexture(texData, width, height, THREE.RGBAFormat);
                //create new geometry buffer with the data extracted previously
                var newGeometry = new THREE.BufferGeometry()
                newGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
                newGeometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
                newGeometry.setIndex(new THREE.BufferAttribute(triangles, 1));
                newGeometry.computeVertexNormals()
                var newMat = new THREE.MeshBasicMaterial()
                newMat.side = THREE.FrontSide
                newMat.map = texture
                var currGeom=ref.current.geometry
                var currOutGeom
                ref.current.geometry = newGeometry
                if (meshRefs[i].hovered||meshRefs[i].clicked){
                    currOutGeom=meshRefs[i].refOut.current.geometry
                    meshRefs[i].refOut.current.geometry = newGeometry
                }
                ref.current.material = newMat

                texture.dispose()
                newGeometry.dispose()
                newMat.dispose()
                currGeom.dispose()
                if (meshRefs[i].hovered||meshRefs[i].clicked){
                    currOutGeom.dispose()
                }
            }
        }
    };
    keyboardControls(){
        for (let i = 0; i < this.state.meshNum; i++) {
            if (meshRefs[i].clicked){
                var ref = meshRefs[i].ref
                var refOut = meshRefs[i].refOut
                ref.current.rotation.y = this.props.controls.meshRotationY[i]
                refOut.current.rotation.y = ref.current.rotation.y
                ref.current.rotation.y = this.props.controls.meshRotationY[i]
                ref.current.scale.set(this.props.controls.meshScale[i],this.props.controls.meshScale[i],this.props.controls.meshScale[i])
                refOut.current.scale.set(this.props.controls.meshScale[i],this.props.controls.meshScale[i],this.props.controls.meshScale[i])
                ref.current.position.set(this.props.controls.meshPos[i].x,this.props.controls.meshPos[i].y,this.props.controls.meshPos[i].z)
                refOut.current.position.set(this.props.controls.meshPos[i].x,this.props.controls.meshPos[i].y,this.props.controls.meshPos[i].z)
            }
        }
    }
    async playFrames() {
        while (true) {
        this.keyboardControls()
            if (mainPlaybackBuffer.length > 0) {
                if (this.props.playerState.status !== playStatus.PAUSED) {
                    //if we were previously buffering, update the state to playing
                    if (this.props.playerState.status === playStatus.BUFFERING) this.props.updateStatus(playStatus.PLAYING)
                    this.setState({
                        nextFrame: mainPlaybackBuffer.shift()
                    }, this.updateBufferGeometry)
                }
            }
            await delay(57)
        }
    }


    startBufferTimer() {

        setInterval(() => {
            console.log(mainPlaybackBuffer.length)
            if (mainPlaybackBuffer.length > 2) {
                mainPlaybackBuffer = frameBuffer
            } else {
                mainPlaybackBuffer = mainPlaybackBuffer.concat(frameBuffer)
            }
            frameBuffer = [];
        }, 198)
    }


    receivedReaderWorkerMessage(event) {
        if (event.data.type === 'decoderReady') {
            this.props.setStreamReady(true)
            console.log('Ready')
            //whether the batch worker is ready
            this.setState({ batchWokerReady: true })
        } else
            if (event.data.type === 'Processed Frame') {
                var batchStart = event.data.batchStart
                //use batch only if it's still valid
                var batch = this.state.frameQueue
                var geometry
                if (this.state.dracoEncoding) {
                    geometry = getGeometry(event.data.geometry);
                } else {
                    geometry = event.data.geometry
                }
                let textureData = { buf: event.data.textureData, height: event.data.textureHeight, width: event.data.textureWidth }
                let mesh = { geometry: geometry, textureData: textureData };
                if (!batch.hasOwnProperty(event.data.frameBatchPos)) batch[event.data.frameBatchPos] = {};
                (batch[event.data.frameBatchPos])[event.data.meshID] = mesh;
                this.setState({ frameQueue: batch })
                if (event.data.decodingJobsLeft === 0) {
                    var frameArray = getSortedFrames(batch)
                    //only start geometry updater at the beginning
                    if (!this.state.loaded) {
                        var refs = []
                        var baseMeshList = []
                        var meshNum = this.state.meshNum
                        //create build meshes and create references to them
                        for (let i = 0; i < meshNum; i++) {
                            var ref = React.createRef()
                            var refOut = React.createRef()
                            var refObject={
                                "ref":ref,
                                "refOut":refOut,
                                "hovered":false,
                                "clicked":false
                            }
                            refs.push(refObject)
                            var baseMesh = BuildMesh(i, ref,refOut,this.props.amtc,this.props.rmfc,this.props.imic)
                            baseMeshList.push(baseMesh)
                        }
                        frameBuffer = frameArray
                        meshRefs= refs
                        this.setState({
                            frameQueue: {},
                            loaded: true,
                            currMeshList: baseMeshList
                        })
                        this.startBufferTimer();
                        this.playFrames()
                    } else {
                        frameBuffer = frameBuffer.concat(frameArray)
                        this.setState({
                            frameQueue: {}
                        })
                    }
                    this.state.onGoingReaderRequests.delete(batchStart)
                }

            }else if (event.data.type === 'Audio Data'&& !this.props.isMuted){
                //const audioData=event.data.data
                bufferedAudioData.push(new Float32Array(event.data.data))
                if (bufferedAudioData.length>0&&!initializedAudio){
                    feeder.init(1, 44100);
                    feeder.waitUntilReady(function() {
                        feeder.bufferData([
                          bufferedAudioData.shift()
                        ]);
                      
                        // Start playback...
                        feeder.start();
                        // Callback when buffered data runs below feeder.bufferThreshold seconds:
                        feeder.onbufferlow = function() {
                            if (bufferedAudioData.length>0){
                                feeder.bufferData([
                                    bufferedAudioData.shift()
                                ]);
                            }
                        };
                        feeder.onstarved = function() {
                            console.log("audio buffer starved!")
                            if (bufferedAudioData.length>0){
                                feeder.bufferData([
                                    bufferedAudioData.shift()
                                ]);
                            }else{
                                console.log("stopping audio.")
                                feeder.stop()
                                initializedAudio=false
                            }
                        };
                      });
                      initializedAudio=true
                }
                //if (!initializedAudio){this.audioUpdates()}
            }
    }

    sendBatchToWorker(batchData) {
        if (this.props.playerState.status !== playStatus.PAUSED && this.state.batchWokerReady) {
            // eslint-disable-next-line
            //var decodedData = B64.decode(batchData)
            //console.log('\n base64 size: ', batchData.length);
            //console.log('\n decoded data size: ', decodedData.length);
            var currKeyFrame = batchData.key_frame_id
            if (!started && currKeyFrame !== 0) {
                //discard frames until a new keyframe
                //console.log("Skipping non-keyframe...")
            } else {
                if (!started) {
                    started = true;
                }
                if (currKeyFrame === prevKeyFrame + 1 || currKeyFrame==0|| currKeyFrame==1000) {

                    var message = {
                        type: 'Batch',
                        batch: B64.decode(batchData.frame),
                        timelinePos: this.props.playerState.timelinePos,
                        pos: currIdx,
                        keyframeID: batchData.key_frame_id
                    }
                    this.state.readerWorker.postMessage(message)
                } else {
                    //discard frames until a new keyframe
                    console.log("Frame lost. Skipping non-keyframe...")
                }
                if (currKeyFrame!=1000){
                    currIdx = currIdx + 1
                    prevKeyFrame = currKeyFrame
                }
            }
        }
    }

    //Toogle MeshNum
    toggleMeshNum = () => {
        const q_params = new URLSearchParams(window.location.search);
        let getMsn = 1
        if (parseInt(q_params.get('msn'))) {
            getMsn = parseInt(q_params.get('msn'))
            // this.setState({ meshNum: getMsn })
            // eslint-disable-next-line
            this.state.meshNum = getMsn;
            console.log('meshNum= ', this.state.meshNum, getMsn)
        }
    }

    componentDidMount() {
        // meshNum
        this.toggleMeshNum()
        var readerWorker = new Worker(process.env.PUBLIC_URL + "/ReaderWorker.js", { type: "module" })
        readerWorker.onmessage = this.receivedReaderWorkerMessage
        var workerList = []
        var worker = new Worker(process.env.PUBLIC_URL + "/Decoder.js")

        workerList.push(worker)
        this.setState({
            readerWorker: readerWorker,
            decoderWorkers: workerList
        })
        var message = {
            type: 'Setup',
            dracoEncoding: this.state.dracoEncoding,
            url: process.env.PUBLIC_URL,
            workerNum: this.state.meshNum
        }
        readerWorker.postMessage(message)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.timelinePos !== this.props.timelinePos) {
            if (this.state.loaded) {
                this.props.pageLoaderCheck(this.state.loaded, this.props.setCheckLoader)
            }
        }

        if (prevProps.getMesh !== this.props.getMesh) {
            this.setState({
                positioner: this.props.getMesh
            })
        }
        if (prevProps.msn !== this.props.msn) {
            this.toggleMeshNum()
        }
        // live frames
        if (prevProps.getMsg !== this.props.getMsg) {
            //console.log(this.props.streamData)
            localStorage.setItem('msg', this.props.getMsg)
            //let stream = localStorage.getItem('msg')
            this.sendBatchToWorker(this.props.streamData)
        }

    }

    //return null;

    render() {
        if (this.state.loaded) {
            return this.state.currMeshList
        } else {
            return null
        }
    }
}


export default ReadArchive;