import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import CheckChrome from '../components/Alerts/CheckChrome';
import StandardViewer from "../pages/StandardViewer";
import StandardStream from "../pages/StardardStream";
// import ARS3FsMode from "../pages/ARLogic/viewer.AR/AR.S3FsMode";
import ARModelTest from "../pages/ARLogic/ARModelTest";
import Login from "../pages/Account/Login"
import MainPage from "../pages/mainPage";
import ProfilePage from "../pages/profilePage";

function Routes() {
    // eslint-disable-next-line
    const [isChrome, setIsChrome] = React.useState(true)
    React.useEffect(() => {
        var isIOSChrome = window.navigator.userAgent.match('CriOS')
        var android = window.navigator.userAgent.match(/Android/i)
        var iphone = window.navigator.userAgent.match(/iPhone/i)
        // it is chrome on android
        setIsChrome(() => window.chrome && (window.chrome || android) && !isIOSChrome && !iphone)

    }, [setIsChrome])
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/login" component={localStorage.getItem('Username') ? ProfilePage:Login} />
                <Route exact path="/profile" component={ProfilePage} />
                <Route exact path="/" component={MainPage} />
                <Route exact path="/viewer" component={isChrome ? StandardViewer : CheckChrome} />
                <Route exact path="/viewer-ar" component={isChrome ? StandardViewer : CheckChrome} />
                
                <Route exact path="/stream" component={isChrome ? StandardStream : CheckChrome} />
                <Route exact path="/stream-ar" component={isChrome ? StandardStream : CheckChrome} />
                
                <Route exact path="/stream/:uniqueId" component={isChrome ? StandardStream : CheckChrome} />
                <Route exact path="/stream-ar/:uniqueId" component={isChrome ? StandardStream : CheckChrome} />
                
                <Route exact path="/ar-check" component={ARModelTest} />

                
                <Redirect from="/vr" to="/viewer?archive_id=hiroki1" />
                <Redirect from="/ar" to="/viewer-ar?archive_id=hiroki3" />
                <Redirect from="/" to="/viewer?archive_id=hiroki1" />

                <Route component={StandardViewer} />

            </Switch>
        </BrowserRouter>
    )
}

export default Routes;
