import React from "react";
import { useLoader, useThree } from "@react-three/fiber"
import * as THREE from "three";
import { CubeTextureLoader } from 'three'
import { Environment } from "@react-three/drei"
import { memo } from 'react';

const Water = () => {
    var url = 'https://holotch-viewer-background-scenes.s3.ap-northeast-1.amazonaws.com/beach/'
    const nrml = useLoader(THREE.TextureLoader, url + 'Water_Normal_DirectX.jpg')
    nrml.wrapS = THREE.RepeatWrapping;
    nrml.wrapT = THREE.RepeatWrapping;
    nrml.repeat.x = 5;
    nrml.repeat.y = 25;
    const base = useLoader(THREE.TextureLoader, url + 'Water_Base_Color.jpg');
    base.wrapS = THREE.RepeatWrapping;
    base.wrapT = THREE.RepeatWrapping;
    base.repeat.x = 5;
    base.repeat.y = 25;
    const rough = useLoader(THREE.TextureLoader, url + 'Water_Roughness.jpg');
    rough.wrapS = THREE.RepeatWrapping;
    rough.wrapT = THREE.RepeatWrapping;
    rough.repeat.x = 5;
    rough.repeat.y = 25;

    return (
        <group>
            <mesh receiveShadow rotation-x={-Math.PI / 2} scale={[1000, 3000, 1]} position={[-650, -9, 0]}>
                <planeBufferGeometry attach='geometry' args={[1, 1]} />
                <meshPhysicalMaterial attach='material' map={base} normalMap={nrml} roughnessMap={rough} />
            </mesh>
        </group>
    );
}

function processClick(e,handleClickTranslation){
    console.log(e)
    if (e.button==2){
        handleClickTranslation(e.point)
    }
}

const Floor = ({handleClickTranslation}) => {
    var url = 'https://holotch-viewer-background-scenes.s3.ap-northeast-1.amazonaws.com/beach/'
    const nrml = useLoader(THREE.TextureLoader, url + 'Beach_Normal_DirectX.jpg')
    nrml.wrapS = THREE.RepeatWrapping;
    nrml.wrapT = THREE.RepeatWrapping;
    nrml.repeat.x = 30;
    nrml.repeat.y = 120;
    const base = useLoader(THREE.TextureLoader, url + 'Beach_Base_Color.jpg');
    base.wrapS = THREE.RepeatWrapping;
    base.wrapT = THREE.RepeatWrapping;
    base.repeat.x = 30;
    base.repeat.y = 120;
    const rough = useLoader(THREE.TextureLoader, url + 'Beach_Roughness.jpg');
    rough.wrapS = THREE.RepeatWrapping;
    rough.wrapT = THREE.RepeatWrapping;
    rough.repeat.x = 30;
    rough.repeat.y = 120;
    const height = useLoader(THREE.TextureLoader, url + 'Beach_Height.jpg');
    height.wrapS = THREE.RepeatWrapping;
    height.wrapT = THREE.RepeatWrapping;
    height.repeat.x = 30;
    height.repeat.y = 120;
    return (
        <group>
            <mesh name="floor" receiveShadow rotation-x={-Math.PI / 2} scale={[500, 2000, 1000]} position={[100, -10, 0]}>
                <planeBufferGeometry attach='geometry' args={[1, 1, 1000, 1000]} />
                <meshPhysicalMaterial attach='material' map={base} normalMap={nrml} roughnessMap={rough} displacementScale={0.002} displacementMap={height} />
            </mesh>
        </group>
    );
}
const addURLPrefix = x =>
    `https://holotch-viewer-sky-textures.s3.ap-northeast-1.amazonaws.com/Beach/${x}`;
function SkyBox() {


    const skyboxTextures = [
        "nz.jpg",
        "pz.jpg",
        "py.jpg",
        "ny.jpg",
        "px.jpg",
        "nx.jpg"
    ].map(addURLPrefix);
    const { scene } = useThree();
    const [cubeMapTexture] = useLoader(CubeTextureLoader, [skyboxTextures]);
    // Set the scene background property to the resulting texture.
    scene.background = cubeMapTexture;
    return null;
}

function BaseEnvironment({handleClickTranslation}) {
    
    return (
        <React.Suspense fallback={null}>
            <SkyBox />
            <Environment files={' https://holotch-viewer-sky-textures.s3.ap-northeast-1.amazonaws.com/Beach/spiaggia_di_mondello_1k.hdr'} />
            <Floor handleClickTranslation={handleClickTranslation}/>
            <Water />
        </React.Suspense>
    )
}

export default memo(BaseEnvironment)
