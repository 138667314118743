import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ImageLoader from '../../components/pageloaders/ImageLoader';
import { ProfileWrapper,ProfileWrapperDesktop, ItemScrollWrapper, SortSection, SortRow } from "./profile.style";
import { HologramCardWrapper, CardBgWrap, CardImg, HologramDuration, CardDescription, AuthourSection } from "../mainPage/mainPageStyles";
import { toast } from "react-toastify";


export const ProfileSectionMobile = ({ ProfileData }) => {
    return (
        <ProfileWrapper className='text-center mx-auto d-lg-none d-md-block'>
            <ImageLoader src={ProfileData.img} cls='rounded-circle' />
            <figcaption>
                <section className="row py-auto mx-1">
                    <div className="col-6 me-auto text-start">{ProfileData.firstName} {ProfileData.lastName}</div>
                    <div className="col-2 ms-auto text-end ps-4"><i className="fa fa-share"></i></div>
                </section>
                <p className="text-start ps-2 my-3 small">
                    <span>{ProfileData.totalViews > 1000 ? `${ProfileData.totalViews / 1000}k` : ProfileData.totalViews} total views</span>
                    &nbsp; • &nbsp;
                    <span>{ProfileData.follower > 1000 ? `${ProfileData.follower / 1000}k` : ProfileData.follower} followers</span>
                </p>
            </figcaption>
        </ProfileWrapper>
    )
}

export const ProfileSectionDesktop = ({ ProfileData }) => {
    return (
        <ProfileWrapperDesktop className='mx-auto d-md-block d-none'>
            <ImageLoader src={ProfileData.img} width={200} height={200} cls='rounded-circle' />
            <figcaption>
                <section className="row py-auto mx-1">
                    <div className="col-6 me-auto text-start">{ProfileData.firstName} {ProfileData.lastName}</div>
                    <div className="col-2 ms-auto text-end ps-4"><i className="fas fa-cog"></i></div>
                </section>
            </figcaption>
        </ProfileWrapperDesktop>
    )
}

const HologramCard = ({ hologramData, handleShare, autoscroll }) => {
    const options = {
        autoplay: autoscroll,
        nav: false,
        dots: false,
        stagePadding: 0,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1.1,
            },
            400: {
                items: 3,
            },
            800: {
                items: 3,
            },
            900: {
                items: 3,
            },
            1100: {
                items: 3,
            },
        },
    };

    const events = {
        onDragged: function (event) { console.log(event) },
        onChanged: function (event) { console.log(event) }
    };
    return (
        <HologramCardWrapper>
            <div className="row mt-5">
                <OwlCarousel className='owl-theme' events={events} nav loop margin={10} {...options}>
                    {hologramData.length > 0 ? hologramData.map((item, i) => (
                        <div className='col-md-12 col-sm-12 my-3' key={i}>
                            <Link to={'/vr'} className="no-decor">
                                <CardBgWrap className="mx-auto text-center" hologramBg={item.hologramBg}>
                                    <CardImg src={item.hologramImage} alt="" className="img-fluid col-8 mt-5" />
                                </CardBgWrap>
                            </Link>
                            <HologramDuration className="col-4 ms-auto text-end">
                                <span className="bg-dark text-white px-2 me-1 badge badge">{item.hologramDuration}</span>
                            </HologramDuration>
                            <CardDescription className="row mx-0">
                                <div className="col-10 p-0 py-0 mx-0">
                                    <span className=""> {item.hologramTitle.slice(0, 50)}...</span>
                                </div>
                                <div className="col-2 my-auto" onClick={() => handleShare('Holotch.com')}>
                                    <i className="fa fa-share" aria-hidden="true"></i>
                                </div>
                            </CardDescription>
                            <AuthourSection className=''>
                                <li className="my-auto mx-1">
                                    <Link to={'/profile'} className="no-decor text-white">
                                        <img src={item.hologramAuthor.authourImg} alt="" className=""
                                            style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                                    </Link>
                                </li>
                                <li className="my-auto mx-1 mt-1">
                                    <Link to={'/profile'} className="no-decor text-white">{item.hologramAuthor.authorUsername}</Link>
                                </li>
                                <li className="my-auto mx-1 mt-1">
                                    <span className="">• {item.hologramViewCount}k Views
                                    </span>
                                </li>
                                <li className="my-auto mx-1 mt-1">
                                    <span className="">
                                        • {moment(item.hologramTimestamp).format('DD MMM, YYYY')}
                                    </span>
                                </li>
                            </AuthourSection>

                        </div>
                    )) : <></>}
                </OwlCarousel>
            </div>
        </HologramCardWrapper>
    )
};

const UserHologramCard = ({ hologramData, handleShare }) => {

    return (
        <HologramCardWrapper>
            <div className="row">

                {hologramData.length > 0 ? hologramData.map((item, i) => (
                    <div className='col-md-4 my-lg-5 my-3' key={i}>
                        <p className="text-start">
                            {item.publish ? <i className="bi bi-lock"></i> : <i className="bi bi-check"></i>}
                        </p>
                        <Link to={'/vr'} className="no-decor">
                            <CardBgWrap className="mx-auto text-center" hologramBg={item.hologramBg}>
                                <CardImg src={item.hologramImage} alt="" className="img-fluid col-8 mt-" />
                            </CardBgWrap>
                        </Link>
                        <HologramDuration className="col-4 ms-auto text-end">
                            <span className="bg-dark text-white px-2 me-1 badge badge">{item.hologramDuration}</span>
                        </HologramDuration>
                        <CardDescription className="row mx-0">
                            <div className="col-10 p-0 py-0 mx-0">
                                <span className=""> {item.hologramTitle.slice(0, 50)}...</span>
                            </div>
                            <div className="col-2 my-auto" onClick={() => handleShare('Holotch.com')}>
                                <i className="fa fa-share" aria-hidden="true"></i>
                            </div>
                        </CardDescription>
                        <AuthourSection className=''>
                            <li className="my-auto mx-1 mt-1">
                                <span className="">{item.hologramViewCount}k Views
                                </span>
                            </li>
                            <li className="my-auto mx-1 mt-1">
                                <span className="">
                                    • {moment(item.hologramTimestamp).format('DD MMM, YYYY')}
                                </span>
                            </li>
                        </AuthourSection>

                    </div>
                )) : <></>}

            </div>
        </HologramCardWrapper>
    )
};


export const ItemsSideScroll = ({ title, hologramData, handleShare, autoscroll }) => {

    return (
        <>
            <ItemScrollWrapper className="d-lg-none d-md-block">
                <h1 className="fs-6 ps-2">{title}</h1>
                <section className="">
                    <HologramCard
                        hologramData={hologramData}
                        handleShare={handleShare}
                        autoscroll={autoscroll}
                    />
                </section>
            </ItemScrollWrapper>

        </>
    )
}

export const UsersHologram = ({ hologramData, handleShare, showSort, setShowSort }) => {
    const [sortByRescent, setsortByRescent] = React.useState(true);
    const [sortByViews, setsortByViews] = React.useState(false);

    return (
        <>
            <ItemScrollWrapper className="d-lg-none d-md-block">
                <section className="">
                    <UserHologramCard
                        hologramData={hologramData}
                        handleShare={handleShare}
                    />
                </section>
                <SortSection show={showSort}>
                    <section className="col-12">
                        <div className="col-12" onClick={() => { setsortByRescent(true); setsortByViews(false) }}>

                            <div className="col-12 border-bottom">
                                <div className="row">
                                    <div className="col-5 text-center text-start me-auto ms-3 fs-4">
                                        Sort By
                                    </div>
                                    <div className="col-5 text-end text-start me-auto ms-3 fs-4" onClick={() => setShowSort(false)}>
                                        <i className="bi bi-x fs-3"></i>
                                    </div>
                                </div>
                            </div>
                            <SortRow active={sortByRescent} className="row my-1">
                                <div className="col-2 text-center">
                                    {sortByRescent ? <i className="bi bi-check2 fs-3"></i> : <></>}
                                </div>
                                <div className="col-6 text-start me-auto fs-4">
                                    Most Rescent
                                </div>
                            </SortRow>
                        </div>
                        <div className="col-12" onClick={() => { setsortByRescent(false); setsortByViews(true) }}>
                            <SortRow active={sortByViews} className="row my-1">
                                <div className="col-2 text-center">
                                    {sortByViews ? <i className="bi bi-check2 fs-3"></i> : <></>}
                                </div>
                                <div className="col-6 text-start me-auto fs-4">
                                    Most Viewed
                                </div>
                            </SortRow>
                        </div>
                    </section>
                </SortSection>
            </ItemScrollWrapper>

        </>
    )
}

export const handleShare = (data) => {
    if (navigator.share) {
        navigator
            .share({
                title: "Holotch",
                text: data,
                url: "www.holotch.com",
            })
            .then(() => {
                console.log("Successfully shared");
            })
            .catch((error) => {
                console.error("Something went wrong", error);
            });
    } else {
        toast.dark('Your browser does not support the share APIs', {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    // setCameraCapture(false)
};

