import React from 'react';
import { MainPageBaseWrapper, MainPageBaseWrapperDesktop } from './mainPageStyles';
import ImageLoader from '../../components/pageloaders/ImageLoader';


const baseNavData = [
    {
        icon: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/basenav-1.png',
        title: 'Discover',
        tab: '1',
    },
    {
        icon: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/basenav-2.png',
        title: 'Following',
        tab: '2'
    },
    {
        icon: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/basenav-3b.png',
        title: 'Bookmarks',
        tab: '3'
    },
]

export const DestopSideNav = ({ openBaseTab }) => {
    return (
        <>
            <MainPageBaseWrapperDesktop>
                <div className="menu-tab mt-3">
                    {baseNavData.map((data, i) => (
                        <div className={`make-cat-pointer col pt-2 mb-3 text-center menu-tablinks ${i === 0 ? 'active text-white' : 'text-white'}`} key={i} onClick={(e) => openBaseTab(e, data.title + '2')}>
                            <div className=''>
                                <ImageLoader
                                    cls={'rounded-circle img-fluid'}
                                    width={30}
                                    height={30}
                                    spinnerSize={15}
                                    src={data.icon}
                                />
                                <figcaption className=""><small className="">{data.title}</small></figcaption>
                            </div>
                        </div>
                    ))}
                </div>
            </MainPageBaseWrapperDesktop>
        </>
    )
}

function MainBaseNav({ openBaseTab }) {

    return (
        <>
          
            {/* <MainPageBaseWrapperDesktop>
                <div className="menu-tab mt-3">
                    {baseNavData.map((data, i) => (
                        <div className={`make-cat-pointer col pt-2 mb-3 text-center menu-tablinks ${i === 0 ? 'active text-white' : 'text-white'}`} key={i} onClick={(e) => openBaseTab(e, data.title + '2')}>
                            <div className=''>
                                <ImageLoader
                                    cls={'rounded-circle img-fluid'}
                                    width={30}
                                    height={30}
                                    spinnerSize={15}
                                    src={data.icon}
                                />
                                <figcaption className=""><small className="">{data.title}</small></figcaption>
                            </div>
                        </div>
                    ))}
                </div>
            </MainPageBaseWrapperDesktop> */}
        </>
    )
}

export default MainBaseNav
