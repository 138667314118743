import React from 'react';
import { startAR, animate, begin, placer, arBtnClick } from './index';



function ArUi() {
    const getRef = React.useRef();
    React.useEffect(() => {
        startAR();
        animate();
        begin(getRef, true);

    }, [])
    let ar_botton = document.getElementById("ARButton");
    React.useEffect(() => {
        // let ar_botton = document.getElementById("ARButton");
        if (ar_botton) {
            ar_botton.style.display = "block";
            ar_botton.style.backgroundColor = "#7c1eef";
            ar_botton.addEventListener("click", function () {
                arBtnClick();
            })
        }

    }, [ar_botton])

    return (
        <div>
            <div id="content" ref={getRef}>
                <div id="container" style={{ position: "fixed" }}></div>
                <button type="button" className="btn btn-sm btn-outline-white" id="place-button" onClick={placer}>Tap</button>
            </div>
        </div>
    )
}

export default ArUi