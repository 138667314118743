import React from 'react';
import ImageLoader from '../../components/pageloaders/ImageLoader';
import { MainPageNavWrapper } from './mainPageStyles';
import { Link,useHistory } from 'react-router-dom';
var history
const routeChangeSignOut = () =>{ 
  let path = `/`; 
  localStorage.clear()
  history.push('/')
}

const routeChangeLogIn = () =>{ 
    let path = `/login`;
    history.push('/login')
}
export const DestopNav = () => {
    history = useHistory()
    return (
        <>
            <MainPageNavWrapper>
                <div className="row d-lg-flex">
                    <div className="col-3 me-auto my-auto">
                        <Link to={'/'}>
                            <ImageLoader
                                src={'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Holotch+logo.png'}
                                width={90}
                            />
                        </Link>
                    </div>
                    <div className="col-9 text-end float-end ms-auto">
                        <div className="row">
                            <div className="col-8 mx-auto">
                                <form className="">
                                    <div className="input-group d-none">
                                        <input className="form-control border-end-0 border bg-dark text-white" type="search" id="example-search-input" />
                                        <span className="input-group-append">
                                            <button className="btn btn-outline-dark text-white bg-dark border-start-0 border-bottom-0 border ms-n5" type="button">
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </form>
                            </div>
                            <div className="userContainer col-4 mx-auto">
                                <Link to={'/profile'} className="userContainer">
                                    {localStorage.getItem('Username')?
                                            <ImageLoader
                                                cls={'rounded-circle img-fluid'}
                                                width={40}
                                                height={40}
                                                spinnerSize={15}
                                                src={'https://holotch-user-profile-bucket.s3.ap-northeast-1.amazonaws.com/Users/'+localStorage.getItem('Username')+'/profile_pic.png'}
                                            />
                                        :<></>}
                                    <div className='userNameText'>
                                        {localStorage.getItem("Username")}
                                    </div>
                                </Link>
                                <div className='signButtonContainer'>
                                    {localStorage.getItem('Username')?
                                        <button onClick={routeChangeSignOut} className="signButton btn btn-primary"
                                            >
                                            SIGN OUT
                                        </button>                                
                                    :
                                        <button onClick={routeChangeLogIn} className="signButton btn btn-primary">
                                            SIGN IN
                                        </button>                                  
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MainPageNavWrapper >
        </>
    )
}


function MainTopNav() {
    return (
        <>
            <MainPageNavWrapper>
                <DestopNav />
            </MainPageNavWrapper >
        </>
    )
}

export default MainTopNav;


