import styled from 'styled-components';



export const ProfileNavWrapper = styled.div.attrs(props => ({
  className: 'container-fluid'
}))`
position: fixed;
top: 0;
left:0;
z-index: 100;
background: linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212;
// box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
  `;

export const ProfileSectionWrapper = styled.div.attrs(props => ({
  className: props.className
}))`
position: fixed;
top: 0;
left:0;
z-index: 100;
background: linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212;
// box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
  `;

export const ProfileWrapper = styled.div.attrs(props => ({
  className: props.className
}))`
  position: relative;
  top: 100px;
  background: #4c4c4c;
  color: white;
  figcaption{
    position: relative;
    top: 0;
    height: 45px;
    background: rgba(18, 18, 18, 0.5);
    backdrop-filter: blur(4px);
    section{
      padding-top: 10px;
    }
  }
  `
  export const ProfileWrapperDesktop = styled.div.attrs(props => ({
    className: props.className
  }))`
    position: relative;
    background: #4c4c4c;
    padding-left:220px;
    color: white;
    figcaption{
      position: relative;
      top: 0;
      height: 45px;
      background: rgba(18, 18, 18, 0.5);
      backdrop-filter: blur(4px);
      section{
        padding-top: 10px;
      }
    }
    `

export const ProfileSectionBase = styled.p.attrs(props => ({
  className: props.className
}))`
  position: fixed;
  // top: 0; 
    `;


export const ItemScrollWrapper = styled.p.attrs(props => ({
  className: props.className
}))`
  position: relative;
  top: 150px; 
  color: white;
  section{
    position: relative;
    top: -25px;
  }
    `;

export const ProfileNav2Wrapper = styled.div.attrs(props => ({
  className: 'container-fluid'
}))`
    position: fixed;
    top: 100px;
    left:0;
    z-index: 100;
    background: black;
    color: white;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: rgba(255, 255, 255, 0.87);
    section{
      padding: 10px 0 10px 0;
    }
      `;

export const SortSection = styled.div.attrs(props => ({
  className: ''
}))`
    border-radius: 4px;
    overflow-y: hidden;
    width: 100%;
    position: absolute;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212;
    color: white;
    transition-property: all;
    transition: all .5s ease-in-out;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transform-origin: left;    
    z-index: ${({ show }) => show ? '200' : '0'}; 
    left: ${({ show }) => show ? '0' : '1000vh'}; 
    top: 0px; 
    section{
      position: relative;
      top: 10px;
    }
    @media only screen and (max-width: 960px) {
        border-radius: 0px !important;
        position: fixed !important;
        transform-origin: top !important;
        bottom:0 !important;
        left: 0 !important;
        top: ${({ show }) => show ? '820px' : '1000vh'} !important; 
    };
    
    @media only screen and (max-width: 700px) {
        top: ${({ show }) => show ? '530px' : '1000vh'} !important; 
      };
    `;

export const SortRow = styled.div.attrs(props => ({
  className: props.className
}))`
    background: ${({ active }) => active ? 'rgba(255, 255, 255, 0.12)' : ''} !important; 
        `;