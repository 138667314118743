import React from 'react';
import { SwitchBtnOverlay, RoundedPillBtn } from '../utils/hologramui.style';
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
// (setSwitchMode, setSwitchModeActivePhoto, setSwitchModeActiveVideo, setSwitchCaptureBtn)
function SectionTwo({ switchViewerMode, viewerModeProps, recordActive, iosViewer }) {
    const { screenMargin,
        photoMode,
        videoMode,
    } = viewerModeProps;
    return (
        <>
            {!iosViewer ?
                <SwitchBtnOverlay>
                    <MDBContainer className='d-block container-fluid'>
                        <MDBRow>
                            <MDBCol className={`col-8 col-md-${screenMargin.md} m${screenMargin.mx}-auto`}>
                                <RoundedPillBtn icon='' label={'VIDEO'} cls='text-white' active={photoMode} action={() => switchViewerMode('video_mode')} />
                                &ensp;
                                {!recordActive ?
                                    <RoundedPillBtn icon='' label={'PHOTO'} cls='text-white' active={videoMode} action={() => switchViewerMode('photo_mode')} />
                                    : <></>}
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </SwitchBtnOverlay>
                : <></>}
        </>
    )
}

export default SectionTwo
