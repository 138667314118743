import React from 'react';
import { connect } from "react-redux";
import SectionTwo from '../sections/SectionTwo';
import SectionOne from '../sections/SectionOne';
import SectionThree from '../sections/SectionThree';
import * as THREE from "three";
import PlayerTimeLine, { volumeMuted } from '../../components/playertimeline';
import {
    ViewerContainer,
    // ViewerDesktopSideBar,
    MobileWrapper,
    CapturedPhotoOverLay,
} from '../utils/hologramui.style';
import {
    togglePlayPause,
    restartPlayback,
    setMetadata,
    incrementTimelinePos,
    updateStatus,
    forward2Secs,
    changeTimeLinePos,
    pageLoaderCheck,
    // liveStreamTimerFunc,
} from '../../components/holotchViewer/hologram/hologram.helpers';
import {
    switchViewerMode,
    popMenu,
    popSettingsMobile,
    popDetailsMobile,

} from '../utils/hologramUI.redux';
import {
    PlayerGestures,
    PlayerGesturesDesktop,
    // startStreaming,
    // stopRecording,
    startRecording,
    capturePhoto,
    savePhoto,
    handleShare,
    secondsToMinutes,
    iosExpandHandler
} from '../../components/holotchViewer/hologram/hologramUi.helpers';
import BaseEnvironment from '../../components/holotchViewer/VRViewer/Environment';
import { Canvas, useThree } from "@react-three/fiber"
import OrbitControls from "../CamControls";
import { softShadows } from '@react-three/drei'
import ReadArchive from '../../components/holotchViewer/VRViewer/StreamPlayer';
import FrameLoader from '../../components/pageloaders/FrameLoader';
import PageLoader from '../../components/pageloaders/mainpageloader';
import SharingContent from '../../components/SharingContent';
import ImageLoader from '../../components/pageloaders/ImageLoader';
import { useParams } from 'react-router-dom';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import QRCodePage from '../utils/QRCodePage'; import { isMobile } from 'react-device-detect';
import { startAR, animate, begin, placer, arBtnClick } from '../ARLogic';
var controls
var setupMouseCallback=false

softShadows();
const CameraController = () => {
    const { camera, gl } = useThree();
    React.useEffect(
        () => {
            controls = new OrbitControls(camera, gl.domElement);
            controls.minDistance = 3;
            controls.maxDistance = 30;
            controls.maxPolarAngle = Math.PI / 2
            return () => {
                controls.dispose();
            };
        },
        [camera, gl]
    );
    return null;
};



export const playStatus = {
    PLAYING: 0,
    PAUSED: 1,
    BUFFERING: 2,
    FINISHED: 3,
}



function HologramUi({ switchViewerMode, popMenu, popSettingsMobile, popDetailsMobile, viewerModeProps, menuProps, ...props }) {
    const { captureBtn } = viewerModeProps;
    /*
  HOLOGRAM SECTION LOGIC HANDLERS
   */
    const setArPath = (window.location.pathname.split('/')[1] === "stream-ar" ? true : false)
    const [status, setStatus] = React.useState(playStatus.PLAYING)
    const [timelinePos, setTimelinePos] = React.useState(0)
    const [hologramLength, setHologramLength] = React.useState(0)
    const [framerate, setFramerate] = React.useState(0)
    const [checkLoader, setCheckLoader] = React.useState(true)
    const [arView, setARView] = React.useState(false)
    const [cameraCapture, setCameraCapture] = React.useState(false)
    const [capturedPhoto, setCapturedPhoto] = React.useState('')
    const [recordActive, setRecordActive] = React.useState(false)
    const [getLike, setGetLike] = React.useState(false)
    const [streamReady, setStreamReady] = React.useState(false)
    const [archiveId, setArchiveId] = React.useState('')
    const [videoRecTimer, setVideoRecTimer] = React.useState('00:00')
    const [recTimerSec, setRecTimerSec] = React.useState('none')
    const [iosViewer, setIosViewer] = React.useState(false)
    const [iosExpand, setIosExpand] = React.useState(true)
    const [streamData, setStreamData] = React.useState({})
    const [startStreaming, setStartStreaming] = React.useState(false)
    const [arRef, setArRef] = React.useState([])
    const [aHeld, setAHeld] = React.useState(false);
    const [dHeld, setDHeld] = React.useState(false);
    const [liveStreamTimer, setLiveStreamTimer] = React.useState({
        sec: '00',
        min: '00',
        hour: '00',

    })
    const [msn, setMsn] = React.useState(1)
    const { uniqueId } = useParams()
    // const { msn } = useParams()

    function ClickListener(evt,camera,scene,fromMouse){
        evt.preventDefault();
        //console.log(evt)
        if (evt.buttons==2||!fromMouse){    
            var vec = new THREE.Vector3();
            if (fromMouse){
                vec.set(
                    ( evt.clientX / window.innerWidth ) * 2 - 1,
                    - ( evt.clientY / window.innerHeight ) * 2 + 1,
                    0.5 );
            }else{
                if (evt.touches.length!=1)return;
                vec.set(
                    ( evt.touches[0].clientX / window.innerWidth ) * 2 - 1,
                    - ( evt.touches[0].clientY / window.innerHeight ) * 2 + 1,
                    0.5 );
            }
            var raycaster = new THREE.Raycaster();

            raycaster.setFromCamera(vec,camera)
            const intersects=raycaster.intersectObjects(scene.children)
            //console.log(intersects)
            for (let i=0;i<intersects.length;i++){
                if (intersects[i].object.name=="floor")handleClickTranslation(intersects[i].point)
            }
            //console.log(intersects)
        }
    }


    function GetClicked3DPoint(){
        const {camera,scene}=useThree()
        React.useEffect(() => {
            if (!setupMouseCallback){
                window.addEventListener('mousedown', (e)=>{ClickListener(e,camera,scene,true)})
                window.addEventListener('touchstart', (e)=>{ClickListener(e,camera,scene,false)})
                setupMouseCallback=true
                return () => {
                    window.removeEventListener('mousedown', (e)=>{ClickListener(e,camera,scene,true)});
                    window.removeEventListener('touchstart', (e)=>{ClickListener(e,camera,scene,false)});
                };
            }
        }, [])
      return <></>
    }


    const refElement = React.useRef()
    const playerProps = {
        status,
        timelinePos,
        hologramLength,
        framerate,
        checkLoader
    };

    // eslint-disable-next-line
    const [getMsg, setGetMsg] = React.useState('');
    const websocketLiveUrl = `wss://${process.env.REACT_APP_WEBSOCKET_AWS}/ws/holotch/${uniqueId !== undefined && uniqueId !== null ? uniqueId : 'test'}/`
    const {
        // eslint-disable-next-line
        sendMessage,
        lastMessage,
        readyState,
    } = useWebSocket(startStreaming ? websocketLiveUrl : null);
    // eslint-disable-next-line
    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];


    var m = 0;
    var s = 0;
    var h = 0;
    let getSec
    let getMin
    let getHour

    // eslint-disable-next-line
    function liveStreamTimerFunc2() {
        var z = '';
        z = (s < 10) ? '0' : '';
        getSec = z + s;
        s++;
        if (s === 60) {
            s = 0;
            z = (m < 10) ? '0' : '';
            getMin = z + m;
            m++;
            if (m === 60) {
                m = 0;
                z = (h < 10) ? '0' : '';
                getHour = z + h;
                h++;
            }
        }
        setTimeout(liveStreamTimerFunc2, 1000);
        setLiveStreamTimer({
            sec: getSec,
            min: getMin,
            hour: getHour,
        })
    }

    React.useEffect(() => {
        const q_params = new URLSearchParams(props.location.search);
        setMsn(q_params.get('msn'));
    }, [props.location.search, msn]);

    React.useEffect(() => {
        const q_params = new URLSearchParams(props.location.search);
        setArchiveId(q_params.get('archive_id'));
        if (archiveId !== '') {
            setIosViewer(true)
        }
    }, [props.location.search, archiveId]);

    React.useEffect(() => {
        const q_params = new URLSearchParams(props.location.search);
        setMsn(parseInt(q_params.get('msn')));
    }, [props.location.search, msn]);



    React.useEffect(() => {
        if (lastMessage !== null) {
            const getFrameData = JSON.parse(lastMessage.data);
            if (getFrameData && getFrameData.frame) {
                console.log('\n reading Frame ', getFrameData.frame_id);
                setGetMsg(getFrameData.frame);
                setStreamData(getFrameData);

            }
        }

    }, [lastMessage]);

    const restartStream = () => {
        if (startStreaming) {
            let socket = new WebSocket(websocketLiveUrl);
            let sendFinishedData = {
                "command": "get_next_frame",
                "sender": "readerWorker",
            };
            let signal = JSON.stringify({ ...sendFinishedData })
            if (streamReady) {
                // setGetMsg('');
                socket.onopen = function () {
                    socket.send(signal);
                }
            }

        };
    }

    const toggleArVr = () => {
        setARView(!arView);
    }

    function handleClickTranslation(pos){
        controls.translateMeshes(pos)
    }

    function initMeshInControlled(angle,scale,position){
        controls.meshRotationY.push(angle)
        controls.meshScale.push(scale)
        controls.meshPos.push(position)
    }
    function addMeshToControlled(i){
        controls.meshesControlled.push(i)
    }
    function removeMeshFromControlled(i){
        controls.meshesControlled=controls.meshesControlled.filter(e => e !== i)
    }    
    function downHandler(e) {
        if (e.keyCode === 65) {
          setAHeld(true);
        }
        if (e.keyCode === 68) {
          setDHeld(true);
        }
      }
    
      function upHandler(e) {
        if (e.keyCode === 65) {
          setAHeld(false);
        }
        if (e.keyCode === 68) {
          setDHeld(false);
        }
      }
    React.useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        return () => {
          window.removeEventListener('keydown', downHandler);
          window.removeEventListener('keyup', upHandler);
        };
    }, [])
    
    React.useEffect(() => {
        if (setArPath) {
            startAR(); animate();
        }
    }, [setArPath])

    React.useEffect(() => {
        if (isMobile && setArPath) {
            let arBtnId = document.getElementById("ARButton");
            if (arBtnId && arRef && arRef.current && checkLoader) {
                begin(arRef, false);
                arBtnId.style.backgroundColor = "#7c1eef";
                arBtnId.style.display = "block";
                arBtnId.addEventListener("click", function () {
                    arBtnClick();
                })
            } else if (arBtnId && arRef == null) {
                arBtnId.style.display = "none";
            }
        }
        // eslint-disable-next-line
    }, [arRef && arRef.current ? arRef : null])

    return (
        <>
            {!isMobile && setArPath ? <QRCodePage /> : <>
                <PageLoader
                    archivePreLoader={true}
                    mainLoader={false}
                    viewerLoader={checkLoader}
                    setStartStreaming={setStartStreaming}
                />

                <ViewerContainer id="mainScreen2">
                    <div className="col-md-12">
                        {/* Top Screen Floating buttom components  */}
                        <SectionOne
                            popSettingsMobile={popSettingsMobile}
                            popDetailsMobile={popDetailsMobile}
                            arView={arView}
                            startStreaming={startStreaming}
                            toggleArVr={toggleArVr}
                            restartStream={restartStream}
                            videoRecTimer={videoRecTimer}
                            recTimerSec={recTimerSec}
                            iosViewer={iosViewer}
                            iosExpand={iosExpand}
                        />
                        <PlayerGesturesDesktop
                            popMenu={popMenu}
                            menuProps={menuProps}
                            popDetailsMobile={popDetailsMobile}
                            popSettingsMobile={popSettingsMobile}

                        />

                        <SharingContent
                            handleShare={handleShare}
                            getLike={getLike}
                            setGetLike={setGetLike}
                            iosViewer={iosViewer}
                        />
                        <CapturedPhotoOverLay display={cameraCapture ? 'block' : 'none'}>
                            <div className="row mx-1">
                                <div className="col-12 card-header" style={{ background: "linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212" }}>
                                    <div className="row">
                                        <div className="col-6 text-start" onClick={() => setCameraCapture(false)}>
                                            <i className="bi bi-x fs-2"></i> <span className="">&ensp; Photo capture</span>
                                        </div>
                                        <div className="col-6 text-end" onClick={() => setCameraCapture(false)}>
                                            <i className="bi bi-trash fs-4"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-10 col-lg-7 mx-auto">
                                    <ImageLoader
                                        cls={`img-fluid captureOverlay `}
                                        spinnerSize={15}
                                        src={capturedPhoto}
                                    />
                                </div>
                                <div className="col-12 card-header pb-4" style={{ background: "linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212" }}>
                                    <div className="row">
                                        <div className="col-7 text-start mx-lg-auto mb-2" onClick={() => handleShare('')}>
                                            <i className="fa fa-share"></i> <span className="">&ensp; Share</span>
                                        </div>
                                        <div className="col-7 text-start mx-lg-auto" onClick={() => savePhoto(capturedPhoto, setCameraCapture)}>
                                            <i className="bi bi-save"></i> <span className="">&ensp; Save to photos</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CapturedPhotoOverLay>
                        <MobileWrapper id={setArPath ? "container" : ''}>
                            <Canvas
                                linear shadows
                                mode="concurrent"
                                camera={{ fov: 75 }}
                                id='hologram__section'
                                className={setArPath ? "ar__canvas" : ''}
                                ref={refElement}
                                gl={{
                                    preserveDrawingBuffer: true,
                                }}
                            >
                                <GetClicked3DPoint/>
                                <CameraController />
                                <directionalLight castShadow position={[0, 10, 0]} color='#FFC08A' intensity={0.3}
                                    shadow-mapSize-width={1024}
                                    shadow-mapSize-height={1024}
                                    shadow-camera-far={50}
                                    shadow-camera-left={-10}
                                    shadow-camera-right={10}
                                    shadow-camera-top={10}
                                    shadow-camera-bottom={-10}
                                />
                                {setArPath ? <></> : <BaseEnvironment />}
                                <ReadArchive
                                    isMuted={volumeMuted}
                                    pageLoaderCheck={pageLoaderCheck}
                                    setCheckLoader={setCheckLoader}
                                    setMetadata={setMetadata}
                                    setHologramLength={setHologramLength}
                                    setFramerate={setFramerate}
                                    incrementTimelinePos={incrementTimelinePos}
                                    setTimelinePos={setTimelinePos}
                                    restartPlayback={restartPlayback}
                                    updateStatus={updateStatus}
                                    setStatus={setStatus}
                                    forward2Secs={forward2Secs}
                                    changeTimeLinePos={changeTimeLinePos}
                                    playerState={playerProps}
                                    timelinePos={timelinePos}
                                    hologramLength={hologramLength}
                                    framerate={framerate}
                                    status={status}
                                    scale={0.8}
                                    setStreamReady={setStreamReady}
                                    getMsg={getMsg}
                                    archiveId={archiveId ? archiveId : uniqueId}
                                    msn={msn}
                                    streamData={streamData}
                                    noOfCam={uniqueId !== undefined ? uniqueId.at(-1) : 2}
                                    setArRef={setArRef}
                                    controls={controls}
                                    amtc={addMeshToControlled}
                                    rmfc={removeMeshFromControlled}
                                    imic={initMeshInControlled}                                    
                                />
                            </Canvas>
                            {setArPath ? <button type="button" className="btn btn-sm btn-outline-white" id="place-button" onClick={placer}>Tap</button> : null}
                            {/* <FrameLoader loaded={checkLoader} setIndex={true} /> */}
                        </MobileWrapper>



                        <div className="d-lg-none d-block">
                            <PlayerGestures
                                popMenu={popMenu}
                                menuProps={menuProps}
                                popDetailsMobile={popDetailsMobile}
                                popSettingsMobile={popSettingsMobile}
                            />
                        </div>
                        {/* Video and Photo switch components  */}
                        <SectionTwo
                            switchViewerMode={switchViewerMode}
                            viewerModeProps={viewerModeProps}
                            recordActive={recordActive}
                            iosViewer={iosViewer}
                        />

                        {/* AR/VR Switch component  */}
                        <SectionThree
                            captureBtn={captureBtn}
                            refElement={refElement}
                            recordActive={recordActive}
                            arView={arView}
                            viewerModeProps={viewerModeProps}
                            toggleArVr={toggleArVr}
                            capturePhoto={capturePhoto}
                            startRecording={startRecording}
                            setRecordActive={setRecordActive}
                            setCapturedPhoto={setCapturedPhoto}
                            setCameraCapture={setCameraCapture}
                            secondsToMinutes={secondsToMinutes}
                            setVideoRecTimer={setVideoRecTimer}
                            setRecTimerSec={setRecTimerSec}
                            iosViewer={iosViewer}
                            handleShare={handleShare}
                            archiveId={archiveId}
                            iosExpand={iosExpand}
                            liveStreamDemo={false}
                            is_stream={false}
                        />
                        {/* Time line component*/}
                        <PlayerTimeLine
                            maxVal={hologramLength}
                            currentVal={timelinePos}
                            changeTimeLinePos={changeTimeLinePos}
                            setTimelinePos={setTimelinePos}
                            totalTime={new Date((hologramLength && framerate > 0 ? hologramLength / framerate : 0) * 1000).toISOString().substr(11, 8)}
                            currentTime={new Date((timelinePos ? timelinePos / framerate : 0) * 1000).toISOString().substr(11, 8)}
                            playStatus={playStatus}
                            togglePlayPause={togglePlayPause}
                            status={status}
                            setStatus={setStatus}
                            restartPlayback={restartPlayback}
                            iosViewer={iosViewer}
                            iosExpand={iosExpand}
                            setIosExpand={setIosExpand}
                            iosExpandHandler={iosExpandHandler}
                            liveStreamTimer={liveStreamTimer}
                            liveMode={true}
                            setArPath={setArPath}
                        />
                    </div>
                    {/* <ViewerDesktopSideBar cls='col-lg-3 d-none d-lg-block'>
                    <DesktopDetailSection />
                </ViewerDesktopSideBar> */}
                </ViewerContainer >
            </>}
        </>
    )
}

const mapStateToProps = (state) => ({
    viewerModeProps: state.hologramUIReducer,
    menuProps: state.popMenuReducer,
});

export default connect(mapStateToProps, {
    switchViewerMode,
    popMenu,
    popSettingsMobile,
    popDetailsMobile
})(HologramUi);

// export default HologramUi;
