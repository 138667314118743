import React from 'react';

function DesktopCover({ copyLink, copied, setCopied }) {


    return (
        <div className="centralize text-center col-12 text-white">
            {/* <img src="https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Illustrations/Browser_not_supported_Dark.png"
                alt=""
                className="img-fluid" /> */}
            <h1 className="fs-4 roboto-text">Device Not yet supported.</h1>
            <p className="my-2"> <small >We are working on it</small></p>
            <p className="">Please open this page with Google Chrome.</p>
            <div className="border col-6 py-1 text-center mx-auto rounded my-3 px-0">
                <span id="copy-link"
                    contentEditable={true}
                    suppressContentEditableWarning={true}>
                    {window.location.href.slice(0, window.location.href.indexOf('?'))}...
                </span>
                &ensp;
                &ensp;
                &ensp;
                <span className="text-holotch clickable" onClick={() => copyLink('copy-action')} id='copy-action'>
                    {copied ? <>COPIED <i className="bi bi-check"></i></> : 'COPY LINK'}
                </span>
            </div>
            <p className="">{"Don’t"} have Google Chrome? <u className="">
                <a href="https://www.google.com/aclk?sa=l&ai=DChcSEwjt6czbotr3AhVfjWgJHacEB1gYABABGgJ3Zg&sig=AOD64_20tShhUogAcRFNR6ViGwuqbHPdLQ&q&adurl&ved=2ahUKEwjeh7_botr3AhXL57sIHYpgCdoQ0Qx6BAgDEAE"
                    rel="noreferrer"
                    target="_blank"
                    className="text-white"> Get it here</a>
            </u>
            </p>
        </div>
    )
}

export default DesktopCover;