import React from 'react';
import ContentLoader from "react-content-loader"
import { LazyLoaderWrapper } from './pageloader.style';

export const LazyLoader = ({ display }) => {

    return (
        <LazyLoaderWrapper display={display ? 'block' : 'none'}>
            <ContentLoader
                width={375}
                height={550}
                spaad={5}
                viewBox="0 0 300 450"
                backgroundColor="black"
                foregroundColor="#dedede"
            >
                <circle cx="31" cy="31" r="15" />
                <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
                <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
                <rect x="0" y="60" rx="2" ry="2" width="400" height="400" />
            </ContentLoader>

        </LazyLoaderWrapper>
    )
}



export const LazyLoader2 = ({ display }) => {

    return (
        <LazyLoaderWrapper display={display ? 'block' : 'none'}>
            <ContentLoader
                viewBox="0 0 900 407"
                height={607}
                width={1400}
                backgroundColor="black"
                foregroundColor="#dedede"
            >
                <rect x="30" y="20" rx="0" ry="0" width="130" height="23" />
                <rect x="30" y="60" rx="0" ry="0" width="200" height="120" />
                <rect x="30" y="189" rx="0" ry="0" width="200" height="15" />
                <rect x="30" y="211" rx="0" ry="0" width="140" height="15" />
                <rect x="243" y="60" rx="0" ry="0" width="200" height="120" />
                <rect x="243" y="189" rx="0" ry="0" width="200" height="15" />
                <rect x="243" y="211" rx="0" ry="0" width="140" height="15" />
                <rect x="455" y="60" rx="0" ry="0" width="200" height="120" />
                <rect x="455" y="189" rx="0" ry="0" width="200" height="15" />
                <rect x="455" y="211" rx="0" ry="0" width="140" height="15" />
                <rect x="667" y="60" rx="0" ry="0" width="200" height="120" />
                <rect x="667" y="188" rx="0" ry="0" width="200" height="15" />
                <rect x="667" y="209" rx="0" ry="0" width="140" height="15" />
                <rect x="30" y="280" rx="0" ry="0" width="130" height="23" />
                <rect x="30" y="320" rx="0" ry="0" width="200" height="120" />
                <rect x="30" y="450" rx="0" ry="0" width="200" height="15" />
                <rect x="30" y="474" rx="0" ry="0" width="140" height="15" />
                <rect x="243" y="320" rx="0" ry="0" width="200" height="120" />
                <rect x="455" y="320" rx="0" ry="0" width="200" height="120" />
                <rect x="667" y="320" rx="0" ry="0" width="200" height="120" />
                <rect x="243" y="450" rx="0" ry="0" width="200" height="15" />
                <rect x="455" y="450" rx="0" ry="0" width="200" height="15" />
                <rect x="667" y="450" rx="0" ry="0" width="200" height="15" />
                <rect x="243" y="474" rx="0" ry="0" width="140" height="15" />
                <rect x="455" y="474" rx="0" ry="0" width="140" height="15" />
                <rect x="667" y="474" rx="0" ry="0" width="140" height="15" />
            </ContentLoader>

        </LazyLoaderWrapper>
    )
}