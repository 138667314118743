import React from 'react';
import { isMobile } from 'react-device-detect';
import { handleShare } from '../../holotchViewer/hologram/hologramUi.helpers';

function MobileCover() {
  var android = window.navigator.userAgent.match(/Android/i)
  return (
    <div className="centralize text-center text-white">
      <img src="" alt="" className="" />
      <p className="fs-2">😰 </p>
      <h1 className="fs-6 mb-3"> Device Not yet supported.</h1>
      <p className="my-2"> <small >We are working on it</small></p>
     
  <p className="">Please open this page with Google Chrome on your Android Phone</p>   
{android ?
<>
    <div className="my-3">
        {isMobile ?
          <button className='btn btn-white chrome-check' onClick={() => handleShare(window.location.href)}>
            open with chrome
          </button>
          : ""}
      </div> 
       
      <p className="mt-3 text-holotch">
        <a rel="noreferrer" target="_blank"
          href="https://play.google.com/store/apps/details?id=com.android.chrome&hl=en&gl=US"
          className="text-holotch">GET GOOGLE CHROME</a>
      </p> 
      </>: <></>}
    </div>
  )
}

export default MobileCover;