import * as THREE from 'three';
import { ARButton } from './ARButton/ARButton';
// import { VRButton } from './utils/arbutton/VRButton';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import glbmodel from './ARTexture/4.glb';
import hdr from './ARTexture/photo_studio_01_1k.hdr'

var container;
var camera, scene, renderer;
// var controller;

var reticle, pmremGenerator, current_object, controls, isAR, envmap, checkModel;

var hitTestSource = null;
var hitTestSourceRequested = false;

// eslint-disable-next-line
var touchDown, touchX, touchY, touchZ, deltaX, deltaY, finger1, finger2, initial_touches, initialScale;

function arPlace() {
    // if (reticle.visible) {
    //     current_object.position.setFromMatrixPosition(reticle.matrix);
    //     current_object.visible = true;
    // }
}


// let ar_botton = document.getElementById("ARButton");

export function begin(ref, useModel) {
    checkModel = useModel;
    if (current_object != null) {
        scene.remove(current_object);
    }
    // loadModel(ref, useModel);
    if (useModel) {
        loadModel(ref, useModel);
    } else {
        var box = new THREE.Box3();
        if (ref && ref.current) {
            current_object = ref.current;
            //set mesh scale
            initialScale = 0.004;
            current_object.scale.set(initialScale, initialScale, initialScale);
            scene.add(current_object);
            box.setFromObject(current_object);
            arPlace();
            box.getCenter(controls.target);
            controls.update();
            render();
        }
    }

}

export function arBtnClick() {
    if (current_object && current_object.visible) {
        current_object.visible = false;
        //set mesh scale
        if (!checkModel) { current_object.scale.set(0.0009, 0.0009, 0.0009); }
        isAR = true;
    } else {
        if (!checkModel) { current_object.scale.set(0.0009, 0.0009, 0.0009); }
        current_object.visible = true;
        isAR = false;
        console.error("current_object is not defined", current_object);

    }
}


export function placer() {
    arPlace();
}




function loadModel(ref, useModel) {

    new RGBELoader()
        .setDataType(THREE.UnsignedByteType)
        .load(hdr, function (texture) {
            envmap = pmremGenerator.fromEquirectangular(texture).texture;
            scene.environment = envmap;
            texture.dispose();
            pmremGenerator.dispose();
            render();
            var loader = new GLTFLoader()
            loader.load(glbmodel, function (glb) {
                var box = new THREE.Box3();
                current_object = glb.scene;
                scene.add(current_object);
                box.setFromObject(current_object);
                arPlace();
                box.getCenter(controls.target);
                controls.update();
                render();
            });
        });
}

export function startAR() {
    // Initialize some variables to keep track of the initial touches
    let initial_touches = [];

    container = document.createElement('div');
    let getContainer = document.getElementById("container");
    if (getContainer) {
        getContainer.appendChild(container);
    }

    scene = new THREE.Scene();
    window.scene = scene;
    camera = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 0.001, 200);
    var directionalLight = new THREE.DirectionalLight(0xdddddd, 1);
    directionalLight.position.set(0, 0, 1).normalize();
    scene.add(directionalLight);

    var ambientLight = new THREE.AmbientLight(0x222222);
    scene.add(ambientLight);
    renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.xr.enabled = true;
    container.appendChild(renderer.domElement);

    pmremGenerator = new THREE.PMREMGenerator(renderer);
    pmremGenerator.compileEquirectangularShader();

    controls = new OrbitControls(camera, renderer.domElement);
    controls.addEventListener('change', render);
    controls.minDistance = 2;
    controls.maxDistance = 10;
    controls.target.set(0, 0, -0.2);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;


    //AR SETUP

    let options = {
        requiredFeatures: ['hit-test'],
        optionalFeatures: ['dom-overlay'],
    }

    // options.domOverlay = { root: document.getElementById('content') };
    options.domOverlay = { root: document.body };

    document.body.appendChild(ARButton.createButton(renderer, options));


    reticle = new THREE.Mesh(
        new THREE.RingBufferGeometry(0.15, 0.2, 32).rotateX(- Math.PI / 2),
        new THREE.MeshBasicMaterial({ color: 0xCA97FF })
    );

    reticle.matrixAutoUpdate = false;
    reticle.visible = false;
    reticle.side = THREE.DoubleSide;
    scene.add(reticle);

    //

    window.addEventListener('resize', onWindowResize, false);

    renderer.domElement.addEventListener('touchstart', function (e) {
        e.preventDefault();
        //tap to place
        if (reticle.visible) {
            current_object.position.setFromMatrixPosition(reticle.matrix);
            current_object.visible = true;
        }
        initial_touches = e.touches;

        touchDown = true;
        touchX = e.touches[0].pageX;
        touchY = e.touches[0].pageY;
        initial_touches = e.touches;
    }, false);

    renderer.domElement.addEventListener('touchend', function (e) {
        e.preventDefault();
        touchDown = false;
        initial_touches = [];
    }, false);


    if (current_object) {
        current_object.addEventListener('click', function (e) {
            // console.log("click", current_object.scale.x);
        })
    }

    renderer.domElement.addEventListener('touchmove', function (e) {
        e.preventDefault();
        if (!touchDown) { return; };
        deltaX = e.touches[0].pageX - touchX;
        deltaY = e.touches[0].pageY - touchY;
        touchX = e.touches[0].pageX;
        touchY = e.touches[0].pageY;

        // Get the current touches
        let touch1 = e.touches[0];
        let touch2 = e.touches[1];

        // Check if there are two touches
        if (touch1 && touch2) {
            // Get the distance between the initial touches and the current touches
            let initial_distance = distance(initial_touches);
            let current_distance = distance(e.touches);

            // Calculate the scale factor
            let scale_factor = current_distance / initial_distance;

            // Scale the object
            current_object.scale.multiplyScalar(scale_factor);

            // Update the initial touches
            initial_touches = e.touches;
        }
        else {
            rotateObject();
        }
    }, false);

}

function distance(touches) {
    let touch1 = touches[0];
    let touch2 = touches[1];
    let dx = touch1.clientX - touch2.clientX;
    let dy = touch1.clientY - touch2.clientY;
    return Math.sqrt(dx * dx + dy * dy);
}


function rotateObject() {
    if (current_object && reticle.visible) {
        current_object.rotation.y += deltaX / 100;
        current_object.rotation.x += deltaY / 100;
    }
}
/**
 * handler for pinch zoom events
 */

function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);

}

//

export function animate() {
    renderer.setAnimationLoop(render);
    requestAnimationFrame(animate);
    controls.update();
}

function render(timestamp, frame) {
    let arButtonControl = document.getElementById("ARButton");
    if (frame && isAR) {
        var referenceSpace = renderer.xr.getReferenceSpace();
        var session = renderer.xr.getSession();

        if (hitTestSourceRequested === false) {


            session.requestReferenceSpace('viewer').then(function (referenceSpace) {

                session.requestHitTestSource({ space: referenceSpace }).then(function (source) {

                    hitTestSource = source;

                });

            });

            session.addEventListener('end', function () {

                hitTestSourceRequested = false;
                hitTestSource = null;

                isAR = false;

                reticle.visible = false;

                var box = new THREE.Box3();
                box.setFromObject(current_object);
                box.getCenter(controls.target);
            });

            hitTestSourceRequested = true;

        }

        if (hitTestSource) {
            var hitTestResults = frame.getHitTestResults(hitTestSource);
            if (hitTestResults.length) {
                var hit = hitTestResults[0];
                arButtonControl.style.display = "none";
                reticle.visible = true;
                reticle.matrix.fromArray(hit.getPose(referenceSpace).transform.matrix);
            } else {
                reticle.visible = false;
                arButtonControl.style.display = "block";
            }

        }

    }

    renderer.render(scene, camera);

}