import React from 'react';

function EditableTitle(props) {
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            props.handleSubmit()
        }
        if (event.key === 'Escape') {
            props.handleBlur(props.initialValue)
        }
    }
  return (
    <span className='rowflex'>
      {props.showInputEle ? (
        <input
          onKeyDown={handleKeyDown}
          type="text"
          value={props.value}
          onChange={props.handleChange}
          onBlur={()=>{props.handleBlur(props.initialValue)}}
          autoFocus
        />
      ) : (
        <span
          onDoubleClick={props.handleDoubleClick}
          style={{
            display: 'inline-block',
            height: '25px',
            minWidth: '400px',
          }}
        >
          {props.value}
        </span>
      )}
    </span>
  );
}

export default EditableTitle;
