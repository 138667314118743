import React, { useState } from "react";
import { Auth } from 'aws-amplify';
import "../../styles/index.css";
import awsconfig from '../../aws-exports';
import ImageLoader from '../../components/pageloaders/ImageLoader';
import { useHistory } from "react-router";
import {apiEndpoint} from './AuthenticatedOperations'
export const RouteChangeSignOut = () =>{ 
    var history=useHistory()
    localStorage.clear()
    history.push('/')
  }
  
export const RouteChangeLogIn = () =>{ 
    var history=useHistory()
      history.push('/login')
      return <></>
  }

export function refreshSession(){
  var promise=new Promise((resolve,reject) => {
    Auth.currentSession()
    .then(async(data) =>        
    {
      console.log(data)
      //localStorage.setItem('AccessToken', data.accessToken.jwtToken);
      //localStorage.setItem('IdToken', data.idToken.jwtToken);
      //localStorage.setItem('RefreshToken', data.refreshToken.token);
      resolve(data)
    })
    .catch(err => {reject(err)});
  })
  return promise
}