import "../../styles/index.css";
import {RouteChangeLogIn,refreshSession} from "./SessionManagement"

export const apiEndpoint='https://tz3a1n5npi.execute-api.ap-northeast-1.amazonaws.com/Dev'

var defaultHeaders=
{ 
    "Access-Control-Allow-Origin":"*",    
    "Access-Control-Allow-Credentials": "true",
    'Content-Type': 'application/json',
    "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
    "Access-Control-Allow-Headers": "*",
};

export async function getUserRecordings(){
    var tokens=await refreshSession()
    defaultHeaders.Authorization=tokens.idToken.jwtToken
    defaultHeaders.AccessToken= tokens.accessToken.jwtToken
    const requestOptions = {
        method: 'GET',
        headers: defaultHeaders
    };
    return fetch(apiEndpoint+'/getUploadsSelf', requestOptions)
}

export async function deleteHologram(hologramID){
    var tokens=await refreshSession()
    defaultHeaders.Authorization=tokens.idToken.jwtToken
    defaultHeaders.AccessToken= tokens.accessToken.jwtToken
    const requestOptions = {
        method: 'POST',
        headers: defaultHeaders,
        body:JSON.stringify({
            'archive_id':hologramID
        })
    };
    return fetch(apiEndpoint+'/archiveDelete', requestOptions)
}

export async function renameHologram(hologramID,newName){
    var tokens=await refreshSession()
    defaultHeaders.Authorization=tokens.idToken.jwtToken
    defaultHeaders.AccessToken= tokens.accessToken.jwtToken
    const requestOptions = {
        method: 'POST',
        headers: defaultHeaders,
        body:JSON.stringify({
            'archive_id':hologramID,
            'archive_name':newName
        })
    };
    return fetch(apiEndpoint+'/archiveRename', requestOptions)
}

export async function toggleArchivePrivacy(hologramID){
    var tokens=await refreshSession()
    defaultHeaders.Authorization=tokens.idToken.jwtToken
    defaultHeaders.AccessToken= tokens.accessToken.jwtToken
    const requestOptions = {
        method: 'POST',
        headers: defaultHeaders,
        body:JSON.stringify({
            'archive_id':hologramID
        })
    };
    return fetch(apiEndpoint+'/archiveTogglePrivacy', requestOptions)
}


export async function checkArchiveAccess(hologramID){
    var tokens
    try{
        tokens=await refreshSession()
    }catch(err){
        tokens=undefined
    }
    if (tokens!=undefined){
        defaultHeaders.Authorization=tokens.idToken.jwtToken
        defaultHeaders.AccessToken= tokens.accessToken.jwtToken
    }
    defaultHeaders.archive_id=hologramID
    const requestOptions = {
        method: 'GET',
        headers: defaultHeaders
    };
    return fetch(apiEndpoint+'/archiveCheckAccess', requestOptions)

}