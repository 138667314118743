import S3FS from '@hasnat/s3fs';
import { S3 } from "aws-sdk";
import * as B64 from 'base64-arraybuffer';
import { apiEndpoint } from '../../../../pages/Account/AuthenticatedOperations';
import { refreshSession } from '../../../../pages/Account/SessionManagement';

var batchesFetched = {}
var bufferSize = 6
var latestFetched = 0
var requested = 0
var loaded = false
var batchList = []
var fsImpl
var currentlyFetching = false
var remainingFetches = bufferSize
var resetPlayback = false
var waitingBatch = 1
var waiting = false
var currentlySending = false
var reqCounter = 0
var sendingId = 0
const useSDK=false

function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}



// newFetchNextBatch(archiveID, batchID, index, fsImpl)
async function readFrames(fetchNext, batchId, framerate, timelineChanged,base64) {
    console.log("reading " + batchId)
    var framesBuffer = batchesFetched[batchId]
    var offset = 0

    while (currentlySending) {
        await delay(57)
    }
    var frameID = (batchId - 1) * 30
    currentlySending = true
    var i = 0
    //console.log(framesBuffer)
    while (offset <= framesBuffer.byteLength) {
        var header = framesBuffer.slice(offset, offset + 4);
        var headerArray = new Int8Array(header)
        var msg = framesBuffer.slice(offset + 4, offset + 16);
        var msgArray = new Int32Array(msg)
        var msgSize = msgArray[0]
        // var timestamp = msgArray[1]
        // var meshNum = msgArray[2]
        var frame
        if (base64){
            frame = B64.encode(framesBuffer.slice(offset, offset + (msgSize)))
        }else{
            frame=framesBuffer.slice(offset, offset + (msgSize))
        }
        console.log("//-------Send frame------// " + frameID)
        //-------Send frame------//
        //console.log("batchID "+ batchId + "sendingID "+sendingId)
        var idToBeSent=frameID
        if (headerArray[0] === 3 && headerArray[1] === 18) idToBeSent=-1
        if (i == 0) {
            fetchNext(frame, idToBeSent, timelineChanged, batchId != sendingId);
        } else {
            fetchNext(frame, idToBeSent, false, batchId != sendingId);
        }
        
        frameID++
        await delay((1 / framerate) * 1000)

        offset += msgSize
        i++
    }

    delete batchesFetched[batchId]
    currentlySending = false


}

async function waitForBatch(batchID, framerate, fetchNext, timelineChanged,base64) {
    var i = 0
    waiting = true
    while (batchID == waitingBatch && !batchesFetched.hasOwnProperty(batchID)) {
        await delay(57)
        if (i === 10) {
            console.log("Waiting for " + batchID)
            //console.log(batchesFetched)
            i = 0
        }
        i++
    }
    if (batchID == waitingBatch && !resetPlayback) {
        sendingId = batchID
        readFrames(fetchNext, batchID, framerate, timelineChanged,base64)
    }
    waiting = false
}



function decodeAndBuffer(batchDataBody, base64, id) {

    var decoder = new TextDecoder("utf-8");


    var framesBuffer
    if (base64) {
        var batch = decoder.decode(batchDataBody.Body)
        framesBuffer = B64.decode(batch)
    } else {
        framesBuffer = batchDataBody.Body
    }
    batchesFetched[id + 1] = framesBuffer
}


async function downloadBatches(archiveId, batchId, batchList, passDataParams, fetchNext, fetchNum) {
    currentlyFetching = true
    remainingFetches = bufferSize
    if (useSDK){
        for (let i = 1; i <= fetchNum; i++) {
            if (batchList[latestFetched] === undefined) break;
            if (!batchesFetched.hasOwnProperty(latestFetched + 1)) {
                console.log("fetching " + batchList[latestFetched])
                const id = latestFetched
                fsImpl.readFile(`archive/${archiveId}/${batchList[latestFetched]}`, { encoding: 'binary' }).then(batchDataBody => decodeAndBuffer(batchDataBody, passDataParams.base64, id))
            }
            latestFetched++
        }
    }else{
        var requestOptions = {
            method: 'GET',
            headers: { 
                "Access-Control-Allow-Origin":"*",    
                "Access-Control-Allow-Credentials": "true",
                'Content-Type': 'application/json',
                "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
                "Access-Control-Allow-Headers": "*",
                "archive_id":archiveId
            }
        };
        var tokens
        try{
            tokens=await refreshSession()
        }catch(err){
            tokens=undefined
        }
        if (tokens!=undefined) requestOptions.headers["AccessToken"]= tokens.accessToken.jwtToken
        for (let i = 1; i <= fetchNum; i++) {
            if (batchList[latestFetched] === undefined) break;
            if (!batchesFetched.hasOwnProperty(latestFetched + 1)) {
                console.log("fetching " + batchList[latestFetched])
                requestOptions.headers.batch_id=latestFetched+1
                const id = latestFetched
                fetch(apiEndpoint+'/archiveGetURL', requestOptions).then(res=>res.json()).then(js=>{
                    fetch(js.url,
                        {
                            method: 'GET',
                        })
                        .then(data=>data.blob())
                        .then(buf=>buf.arrayBuffer())
                        .then(bin=>{
                            var batchDataBody={"Body":bin}
                            decodeAndBuffer(batchDataBody, passDataParams.base64, id)
                        })                    
                })
            }
            latestFetched++
        }
    }
}

function countAvailable(id) {
    var available = 0
    var checking = id
    const keys = Object.keys(batchesFetched)
    for (let key in batchesFetched) {
        if (key == checking) {
            checking++
            available++
        } else {
            break
        }
    }
    return available
}


export function useS3fs(archiveID, fetchNext, toggleBuffering, getNext, passDataParams, timelineChange) {
    if (archiveID) {
        requested = getNext
        if (!loaded) {
            var options = {
                region: 'eu-west-1',
                accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
                secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
                s3: S3,
                useAccelerateEndpoint: true,
            };
            fsImpl = new S3FS("holotch-service-content", options);
            fsImpl.readdirp(`archive/${archiveID}`).then(function (files) {
                const ne = str => str.replace(/\d+/g, n => n.padStart(8, "0"));
                const nc = (a, b) => ne(a).localeCompare(ne(b));
                batchList = files.sort(nc);
                waitForBatch(getNext + 1, passDataParams.framerate, fetchNext, false,passDataParams.base64)
                downloadBatches(archiveID, getNext + 1, batchList, passDataParams, fetchNext, bufferSize)
            });
            loaded = true
        } else {
            const numAvailable = countAvailable(getNext + 1)
            waitingBatch = getNext + 1
            resetPlayback = timelineChange
            reqCounter++
            if (resetPlayback) {
                reqCounter = 0
                latestFetched = getNext
                remainingFetches = 0
                resetPlayback = false
                toggleBuffering()
                waitForBatch(getNext + 1, passDataParams.framerate, fetchNext, true,passDataParams.base64)
                downloadBatches(archiveID, getNext + 1, batchList, passDataParams, fetchNext, bufferSize)
            } else {
                waitForBatch(getNext + 1, passDataParams.framerate, fetchNext, false,passDataParams.base64)
            }
            console.log(reqCounter)
            if (reqCounter == bufferSize / 2) {
                console.log("fetching more batches")
                reqCounter = 0
                var batchNum = bufferSize
                if (numAvailable >= bufferSize) batchNum = 1
                downloadBatches(archiveID, getNext + 1, batchList, passDataParams, fetchNext, batchNum)
            }
        }
    }
}