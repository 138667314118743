import styled from 'styled-components';


export const MainPageNavWrapper = styled.div.attrs(props => ({
  className: 'container-fluid py-2'
}))`
position: fixed;
top: 0;
left:0;
z-index: 100;
background: linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212;
box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
  `;

export const MainPageBaseWrapper = styled.div.attrs(props => ({
  className: 'container-fluid text-center d-lg-none d-block'
}))`
position: fixed;
bottom: 0;
z-index: 100;
background: linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212;
box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
  `;
export const MainPageBaseWrapperDesktop = styled.div.attrs(props => ({
  className: 'container-fluid text-center d-lg-block d-none'
}))`
position: relative;
// bottom: 0;
left:0;
text-align: center;
background: linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212;
// box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
  `;


export const MainPageCatWrapper = styled.div.attrs(props => ({
  className: 'container-fluid'
}))`
margin-top: 50px;
background: none;
// background: linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212;
box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
  `;


export const HologramCardWrapper = styled.div.attrs(props => ({
  className: 'container-fluid'
}))`
margin-bottom: 60px;  
background: none;
// background:  linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212;
  `;

export const CardBgWrap = styled.div.attrs(props => ({
  className: props.className, id: props.id

}))`
  position: relative;
  background: url(${({ hologramBg }) => hologramBg ? hologramBg : ''});
  transition: opacity 1s ease-out;
  left: 0;
  width: 100%;
  height: 70%;
  justify-content: center;
  align-items: center;
    section{
      position: relative;
      top:50%; 
    }
`;

export const CardImg = styled.img.attrs(props => ({
  className: props.className
}))`
position: relative;
top: -45px;
`
export const HologramDuration = styled.div.attrs(props => ({
  className: props.className
}))`
position: relative;
top: -55px;
`
export const CardDescription = styled.div.attrs(props => ({
  className: props.className
}))`
position: relative;
top: -50px;
background: rgba(18, 18, 18, 0.75);
backdrop-filter: blur(4px);
border-radius: 0px 0px 8px 8px;
color: white;

div>span{
  font-weight: 400;
  text-align: left;
  line-height: 20px;
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0.25px;
  font-family: Roboto;
  padding: 1px 2px;

}
`

export const AuthourSection = styled.ul.attrs(props => ({
  className: props.className
}))`
position: relative;
top: -40px;
list-style-type: none;
margin: 0;
padding: 0;
color: white;
li{
  float: left;
  font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;
/* or 133% */

display: flex;
align-items: center;
letter-spacing: 0.4px;
}
`
