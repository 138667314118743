import React from 'react';
import { ProfileNav1, ProfileNav2,ProfileNav3 } from './profile.nav';
import { ProfileSectionMobile,ProfileSectionDesktop, ItemsSideScroll, handleShare, UsersHologram } from './profile.helpers';
import HologramCard from '../mainPage/HologramCard';
import MainBaseNav from '../mainPage/MainBaseNav';
import { DestopNav } from '../mainPage/MainTopNav';
import { openBaseTab } from '../mainPage/index';
import {LazyLoader,  LazyLoader2 } from '../../components/pageloaders/LazyLoader';
import { DestopSideNav } from '../mainPage/MainBaseNav';
import {getUserRecordings} from "../Account/AuthenticatedOperations"
import ReactPaginate from 'react-paginate';
import { toast } from "react-toastify";
import { refreshSession,RouteChangeLogIn,RouteChangeSignOut } from '../Account/SessionManagement';
import * as AWS from "aws-sdk";
var recs=[]

var pageOffset=0
AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});
AWS.config.region = "ap-northeast-1";
const bucket = new AWS.S3({params: {Bucket: 'holotch-user-profile-bucket'}});

var ProfileData = {
    lastName: '',
    totalViews: 200000,
    follower: 2000,
    follow: true,
    popularHologram: [{

    }],
    recentHologram: [{

    }]
}

const openProfileBaseTab = (e, tabName) => {
    document.getElementById('nav_sec').className = 'd-none';
    document.getElementById('tab_sec').className = '';
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("menu-tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("menu-tablinks");
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    e.currentTarget.className += " active";
}


const openProfileNavTab = (e, tabName) => {
    document.getElementById('nav_sec').className = '';
    document.getElementById('tab_sec').className = 'd-none';
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("nav-tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("nav-tablinks");
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    e.currentTarget.className += " active";
}



function ProfilePage() {
    const [hologramUploads, setNewList] = React.useState([]);
    const [switchToLogin,setSwitchToLogin]=React.useState(false);

    function ListRecordings(){
        const [, updateState] = React.useState();
        const forceUpdate = React.useCallback(() => updateState({}), []);
        const comingSoon = () => {
            toast.dark('Avaliable in a bit please check back soon 😇 ', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
          // Here we use item offsets; we could also use page offsets
      // following the API or data you're working with.
      var itemsPerPage=9
      const [itemOffset, setItemOffset] = React.useState(0);
        //deletes a hologram from the list (frontend)
        function deleteHologramEntry(i){
            var hu=hologramUploads
            //console.log(pageOffset+i)
            hu.splice(pageOffset+i,1);
            setNewList(hu)
            forceUpdate()
        }
        //renames a hologram from the list (frontend)
        function renameHologramEntry(i,newTitle){
            var hu=hologramUploads
            hu[pageOffset+i].hologramTitle=newTitle
            setNewList(hu)
            forceUpdate()
        }
        //renames a hologram from the list (frontend)
        function updatePrivacyHologramEntry(i){
            var hu=hologramUploads
            hu[pageOffset+i].privacy=!hu[pageOffset+i].privacy
            setNewList(hu)
            forceUpdate()
        }
      const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % recs.length;
        pageOffset=newOffset
        setItemOffset(newOffset);
        };
      const endOffset = itemOffset + itemsPerPage;
      console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      const currentItems = hologramUploads.slice(itemOffset, endOffset);
      const pageCount = Math.ceil(hologramUploads.length / itemsPerPage);
     // if (switchToLogin)RouteChangeLogIn();
     return (
        <>
            <HologramCard
                hologramData={currentItems}
                hologramRemover={deleteHologramEntry}
                changeHologramName={renameHologramEntry}
                updateHologramPrivacy={updatePrivacyHologramEntry}
                inProfile={true}
            />
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={pageCount}
                pageRangeDisplayed={4}
                marginPagesDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName="pagination justify-content-center"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                activeClassName="active"
                // eslint-disable-next-line no-unused-vars
                hrefBuilder={(page, pageCount, selected) =>
                  page >= 1 && page <= pageCount ? `/page/${page}` : '#'
                }
                hrefAllControls
              />
        </>
     )
    } 



    const [needsLogin, setNeedsLogin] = React.useState(false);
    React.useEffect(() => {
        ProfileData.img= 'https://holotch-user-profile-bucket.s3.ap-northeast-1.amazonaws.com/Users/'+localStorage.getItem('Username')+'/profile_pic.png'
        ProfileData.firstName= localStorage.getItem('Username')
        document.getElementsByTagName("body")[0].style.background = 'black';
        document.getElementsByTagName("body")[0].style.zIndex = -1;
    }, [])


    React.useEffect(()=>{
            const fetchData = async () => {
                var refreshing=false
                await getUserRecordings().then(response => response.json()).then(data => recs=data).catch(async(err) => {setNeedsLogin(true)})
                //console.log(recs)
                var hu=[]
                if (!needsLogin){
                    recs.forEach((item) => {
                        var hologramEntry={
                            hologramImage: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Platform+Assets/Hologram1.png',
                            hologramBg: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Platform+Assets/Hologram+thumbnail-BG1.png',
                            hologramDuration: '03:14',
                            hologramViewCount: 50,
                            hologramTimestamp: '2021-12-10T12:00:00.000Z',
                            publish: true,
                            hologramAuthor:
                            {
                                authorImg: 'https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Picture.png',
                    
                            }
                        }
                        hologramEntry.hologramTitle=item.Title;
                        hologramEntry.privacy=!Boolean(item.IsPrivate)
                        hologramEntry.hologramAuthor.authorUsername=item.Uploader;
                        hologramEntry.hologramID=item.HologramID
                        hologramEntry.hologramAuthor.authorImg= 'https://holotch-user-profile-bucket.s3.ap-northeast-1.amazonaws.com/Users/'+localStorage.getItem('Username')+'/profile_pic.png'
                        var dmy=item.UploadDate.split('-')
                        var timestamp=dmy[2]+'-'+dmy[1]+'-'+dmy[0]+'T12:00:00.000Z'
                        hologramEntry.hologramTimestamp=timestamp
                        hologramEntry.hologramViewCount=item.ViewsNum
                        hologramEntry.hologramDuration=new Date((item.LengthInFrames && item.Framerate > 0 ? item.LengthInFrames / item.Framerate : 0) * 1000).toISOString().substr(11, 8)
                        hu.push(hologramEntry)
                        }
                    )
                    setNewList(hu)
                }
                //console.log(hologramUploads)
            }
        fetchData()
    }, [localStorage])
    
    return (
        <>
        {/*
            <main className="d-lg-none d-block">
                <ProfileNav1
                    navTab={openProfileNavTab}
                />
                <section id="nav_sec">
                    <div id="nav1" className="nav-tabcontent" style={{ display: 'block' }}>
                        <ProfileSectionMobile ProfileData={ProfileData} />
                        <section className="popular-holograms">
                            <ItemsSideScroll
                                title={'Polular Holograms'}
                                hologramData={hologramUploads}
                                handleShare={handleShare}
                                autoscroll={true}
                            />
                        </section>
                        <section className="recent-holograms">
                            <ItemsSideScroll
                                title={'Recent Holograms'}
                                hologramData={hologramUploads}
                                handleShare={handleShare}
                                autoscroll={false}
                            />
                        </section>
                    </div>

                    <div id="nav2" className="nav-tabcontent">
                        <ProfileNav2
                            setShowSort={setShowSort}
                            showSort={showSort}
                        />
                        <UsersHologram
                            hologramData={hologramUploads}
                            handleShare={handleShare}
                            setShowSort={setShowSort}
                            showSort={showSort}
                        />
                    </div>
                    <div id="nav3" className="nav-tabcontent">
                        <ProfileNav2
                        />
                        <UsersHologram
                            hologramData={hologramUploads}
                            handleShare={handleShare}
                            setShowSort={setShowSort}
                            showSort={showSort}
                        />
                    </div>
                </section>
                <section id="tab_sec">
                    <div id="1" className="menu-tabcontent" style={{ display: 'block' }}>
                        <p className="text-white text-center my-auto pt-5 mt-5">
                            <img src="https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Holotch+logo.png" alt="" className="ing-fluid" />
                        </p>
                    </div>

                    <div id="2" className="menu-tabcontent">
                        <p className="text-white text-center my-auto pt-5 mt-5">
                            <img src="https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Holotch+logo.png" alt="" className="ing-fluid" />
                        </p>
                    </div>
                    <div id="3" className="menu-tabcontent" >
                        <p className="text-white text-center my-auto pt-5 mt-5">
                            <img src="https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Holotch+logo.png" alt="" className="ing-fluid" />
                        </p>
                    </div>
                </section>
                <MainBaseNav openBaseTab={openProfileBaseTab} />
            </main>
            */}
            {needsLogin?
                <RouteChangeLogIn/>
                :
                <main className="container-fluid d-lg-block ">
                    <div className="row">
                        <div className="col-lg-12 mb-5">
                            <DestopNav />
                        </div>
                        <ProfileSectionDesktop ProfileData={ProfileData} />
                        <div className="d-none d-lg-block d-xl-block col-lg-1 mt-5 vh-100 me-auto shadow position-fixed" style={{ background: "linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212" }}>
                            <DestopSideNav
                                openBaseTab={openBaseTab}
                            />
                        </div>
                        
                        <div className="col-lg-11 ms-auto">
                            <div className="d-lg-block border-bottom border-white">
                                <div className="row">
                                    <h1 className="fs-4 text-white py-3 col-3 me-auto text-start">
                                        <span className="">Your uploads</span>
                                    </h1>
                                    <div className="col-6 ms-auto text-end">
                                    </div>
                                </div>
                            </div>                        
                            <ListRecordings/>
                        </div>
                    </div>

                </main>
            }
        </>
    )
}

export default ProfilePage;
