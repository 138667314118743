import React, { useState } from "react";
import { Auth } from 'aws-amplify';
import "../../styles/index.css";
import awsconfig from '../../aws-exports';
import ImageLoader from '../../components/pageloaders/ImageLoader';
import { useHistory } from "react-router";
import { LinkItUrl } from 'react-linkify-it';
// >>New - Configuring Auth Module
Auth.configure(awsconfig);
var history
const RouteChangeLoggedIn = () =>{ 
  history.push('/')
}
function Login() {
  history=useHistory()

  // React States
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [signUp, setSignUp] = useState(false);

  // User Login info
  const database = [
    {
      username: "user1",
      password: "pass1"
    },
    {
      username: "user2",
      password: "pass2"
    }
  ];

  const errors = {
    uname: "Incorrect username or password"
  };

  async function handleSubmit(event){
    //Prevent page reload
    event.preventDefault();

    var { uname, pass } = document.forms[0];
    var username=uname.value
    var password=pass.value

    try {
        const user = await Auth.signIn(username, password);
        //localStorage.setItem('AccessToken', user.signInUserSession.accessToken.jwtToken);
        //localStorage.setItem('IdToken', user.signInUserSession.idToken.jwtToken);
        //localStorage.setItem('RefreshToken', user.signInUserSession.refreshToken.token);
        localStorage.setItem('Username', username);
        //console.log(localStorage)
        setIsSubmitted(true);
        RouteChangeLoggedIn()
    } catch (error) {
        setErrorMessages({ name: "uname", message: errors.uname });
        console.log('error signing in', error);
    }
  };

  async function handleCreateNewAcc(event){
    //Prevent page reload
    event.preventDefault();

    var { email,uname, pass } = document.forms[0];
    var username=uname.value
    var password=pass.value
    var email=email.value
    try {
      const { user } = await Auth.signUp({
          username,
          password,
          attributes: {
              email,          // optional
          },
          autoSignIn: { // optional - enables auto sign in after user is confirmed
              enabled: true,
          }
      });
        const usern = await Auth.signIn(username, password);
        //localStorage.setItem('AccessToken', usern.signInUserSession.accessToken.jwtToken);
        //localStorage.setItem('IdToken', usern.signInUserSession.idToken.jwtToken);
        //localStorage.setItem('RefreshToken', usern.signInUserSession.refreshToken.token);
        localStorage.setItem('Username', username);
        //console.log(localStorage)
        setIsSubmitted(true);
        RouteChangeLoggedIn()
    } catch (error) {
        //setErrorMessages({ name: "uname", message: error });
        console.log('error signing up:', error);
    }
  }

  
  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  // JSX code for login form
  const renderForm = (
    <>
      {!signUp?
        <div className="form">
          <form onSubmit={handleSubmit}>
            <div className="fs-6 pt-0 mt-0 mb-3">
              <label>Username </label>
              <input type="text" name="uname" required />
            </div>
            <div className="fs-6 pt-0 mt-0 mb-3">
              <label>Password </label>
              <input type="password" name="pass" required />
            </div>
            {renderErrorMessage("uname")}
            <button className="btn btn-primary btn-rounded"
                style={{
                    background: 'linear-gradient(180deg, #AB6DFA 0%, #6603E3 100%)'
                }}>
              SIGN IN
           </button>
          </form>
          <div className="mt-5">No account?  <button onClick={()=>{setSignUp(true)}} className="clickableText">Sign up</button> </div>
        </div>
        :
        <div className="form">
        <form onSubmit={handleCreateNewAcc}>
          <div className="fs-6 pt-0 mt-0 mb-3">
            <label>Email </label>
            <input type="text" name="email" required />
          </div>
          <div className="fs-6 pt-0 mt-0 mb-3">
            <label>Username </label>
            <input type="text" name="uname" required />
          </div>
          <div className="fs-6 pt-0 mt-0 mb-3">
            <label>Password </label>
            <input type="password" name="pass" required />
          </div>
          {renderErrorMessage("uname")}
          <button className="btn btn-primary btn-rounded"
              style={{
                  background: 'linear-gradient(180deg, #AB6DFA 0%, #6603E3 100%)'
              }}>
              SIGN UP
          </button>
          <div className="centered mt-5">
            <ul className="bulletList">
                <li>Password must contain a lower case letter</li>
                <li>Password must contain a number</li>
                <li>Password must contain at least 8 characters</li>
                <li>Password must not contain a leading or trailing space</li>      
            </ul>
           </div>
        </form>
        </div>
      }
    </>
  );
  React.useEffect(() => document.body.style.background = 'black', [])
  return (
    
    <div className="container-fluid text-center mb-auto text-white mt-5 mt-lg-0" style={{ paddingTop: '20%' }}>
        <div className="fs-6 pt-0 mt-0 mb-5">
            <ImageLoader
                src="https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/Recolor+-+Hands+Tiny+Unlock.png"
                cls={'img-fluid'}
            />
        </div>
      <div className="login-form">
        {isSubmitted ? <div>User is successfully logged in. Redirecting...</div> : renderForm}
      </div>
    </div>
  );

  
}

export default Login;