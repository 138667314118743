import React from 'react';
import QRCode from 'react-qr-code';

function QRCodePage() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-8 mx-auto" style={{ color: 'rgb(160 27 199)' }}>
                    <div className="m-auto text-center my-5">
                        <div className="mb-3">
                            <img
                                src="https://holotch-platform-assests.s3.ap-northeast-1.amazonaws.com/basic/Logo.png"
                                alt="" className="img-fluid" height={100} width={100} />
                            <figcaption className="">
                                <h1 className="text-center">Holotch</h1>
                            </figcaption>
                        </div>
                        <p className="mt-2">Scan QRCode with you mobile phone for AR experience </p>
                        <div className="text-center mx-auto py-3 shadow">
                            <QRCode value={window.location.href} size={256} fgColor={'rgb(160 27 199)'} />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default QRCodePage;