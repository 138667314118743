import React from 'react';
import { playStatus } from '../../hologram/hologramUi.helpers';
import * as THREE from 'three';
import * as B64 from 'base64-arraybuffer';
import * as AWS from "aws-sdk";
import { useS3fs } from './s3fsHelper'
import { shaderMaterial } from '@react-three/drei';
var AudioFeeder = require('audio-feeder');

var feeder = new AudioFeeder();
var frameBuffer = []
var remainingFrames = 30
var requestingNext = false
var changedTimelinePos = false
var stopPlaying = false
var bufferedAudioData=[]
var initializedAudio=false
var meshClicked=false
var meshRefs= [] //references to the meshes in currMeshList

function toggleBuffering() {
    stopPlaying = !stopPlaying
}
function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

AWS.config.update({
    region: 'eu-west-1',
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});
var dynamodb = new AWS.DynamoDB.DocumentClient({ region: 'ap-northeast-1' })
// var s3 = new AWS.S3();
// eslint-disable-next-line
var BATCH_SIZE = 2
// var BUFFER_SIZE = 2
// var archive_id = 'aeb2bd40-77b8-11ec-8c9c-9ba4ebee5585'

function getGeometry(encodedGeom) {
    if (encodedGeom !== null) {
        var decoder = new TextDecoder("utf-8");
        var view = new DataView(encodedGeom, 0, encodedGeom.byteLength);
        var string = decoder.decode(view);
        var geometry = JSON.parse(string);
        return geometry
    } else {
        return null
    }

}


function getSortedFrames(batch) {
    // console.log("batch ", batch)
    const ordered = Object.keys(batch).sort().reduce(
        (obj, key) => {
            obj[key] = batch[key];
            return obj;
        },
        {}
    );
    var frameArray = []
    Object.keys(ordered).forEach(key => {
        frameArray.push(ordered[key])
    });
    return frameArray
}

const vShader=
    /*glsl*/ `
  uniform float size;
  
  void main() {
    vec4 pos = modelViewMatrix * vec4( position + normal * size, 1.0 );
    gl_Position = projectionMatrix * pos;
  }
  `
  const fShader=
    /*glsl*/ `
  uniform vec3 color;
  
  void main() {
    gl_FragColor = vec4(color, 1.);
  }
  `
function changeMat(ref,outRef,idx){
    meshRefs[idx].hovered=!meshRefs[idx].hovered
    if (!meshRefs[idx].clicked){
        if (!meshRefs[idx].hovered){
            outRef.current.geometry=new THREE.BufferGeometry()
        }else{
            var uniforms= {
                size: { value: 20.0 },
                color: new THREE.Uniform( new THREE.Vector3(0.8, 0.8, 0.01) )
            };

            var mat=new THREE.ShaderMaterial({
                vertexShader: vShader,
                fragmentShader: fShader,
                uniforms
            });
            mat.side=THREE.BackSide
            mat.depthWrite=false
            outRef.current.material= mat
            outRef.current.geometry = ref.current.geometry
        }
    }
}

function handleClick(ref,outRef,idx,cancel,amtc,rmfc){
    if (cancel){
        meshRefs[idx].clicked=false
        outRef.current.geometry=new THREE.BufferGeometry()
        rmfc(idx)
    }else{
        meshRefs[idx].clicked=!meshRefs[idx].clicked
        if (!meshRefs[idx].clicked){
            rmfc(idx)
            outRef.current.geometry=new THREE.BufferGeometry()
        }else{
            var uniforms= {
                size: { value: 20.0 },
                color: new THREE.Uniform( new THREE.Vector3(0.588, 0.329, 1.0) )
            };
            amtc(idx,ref.current.rotation.y)
            var mat=new THREE.ShaderMaterial({
                vertexShader: vShader,
                fragmentShader: fShader,
                uniforms
            });
            mat.side=THREE.BackSide
            mat.depthWrite=false
            outRef.current.material= mat
            outRef.current.geometry = ref.current.geometry
        }
    }
}

function BuildMesh(idx, ref,refOut,amtc,rmfc,imic) {
    const scale=0.007
    const angle=Math.PI
    const position=new THREE.Vector3(2, -10, -15)
    imic(angle,scale,position)
    return (
        <group key={idx}>
            <mesh onPointerMissed={(e)=>{if(e.button==0&&e.pointerType=="mouse")handleClick(ref,refOut,idx,true,amtc,rmfc)}} onClick={(e)=> handleClick(ref,refOut,idx,false,amtc,rmfc)} onPointerLeave={(e) => changeMat(ref,refOut,idx)} onPointerOver={(e) => changeMat(ref,refOut,idx)} ref={ref} castShadow={true} receiveShadow={false} scale={scale} rotation-y={angle} position={[2, -10, -15]}>

                <bufferGeometry
                    attach="geometry"
                    onUpdate={self => self.computeVertexNormals()}
                >

                <meshBasicMaterial attach='material' side={THREE.DoubleSide} color={'yellow'} />

                </bufferGeometry>

            </mesh>
            <mesh ref={refOut}  castShadow={true} receiveShadow={false} scale={0.007} rotation-y={Math.PI} position={[2, -10, -15]}>

                <bufferGeometry
                    attach="geometry"
                    onUpdate={self => self.computeVertexNormals()}
                >

                <meshBasicMaterial attach='material' side={THREE.DoubleSide} color={'yellow'} />

                </bufferGeometry>

            </mesh>
        </group>
        )
}

class ReadArchive extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            //
            loaded: false, //whether the first frame has been loaded
            buildingBuffer: true, //whether it's currently accumulating batches to get ahead of user position
            bufferCounter: 3, //how many batches left for accumulation
            //worker data
            readerWorker: null, //web worker for parsing mesh data
            decoderWorkers: [], //web workers for decoding texture data
            onGoingReaderRequests: new Set(),//keep track of ongoing reader worker jobs 
            //buffers
            batchBuffer: {}, //batches currently loaded for playback
            fetchedBatches: {},//batches currently fetched from s3
            framesFromDecoder: {}, //accumulates frames from the batch currently being processed
            frameQueue: {}, //accumulates frames from the batch currently being processed

            //rendering data
            nextFrame: null, //next frame in the frameBuffer          
            currMeshList: [], //meshes to be rendered
            meshNum: 0, //number of meshes of recording
            //batch position and request tracking
            playbackBatch: 1, //playback position batch-wise
            processingBatch: 1, //next batch to be sent to ReaderWorker/decoder
            fetchingBatch: 1, //batch currently being fetched


            //archive data storage info
            lengthInBatches: 0, //recording length in batches
            dracoEncoding: true,
            base64Encoding: true,
            getBatchData: 0,
            passDataParams: {}
        };
        this.playFrames = this.playFrames.bind(this)
        this.fetchNextBatch = this.fetchNextBatch.bind(this)
        this.newFetchNextBatch = this.newFetchNextBatch.bind(this)
        this.receivedReaderWorkerMessage = this.receivedReaderWorkerMessage.bind(this)
        this.updateBufferGeometry = this.updateBufferGeometry.bind(this)
        this.sendBatchToWorker = this.sendBatchToWorker.bind(this)
        this.setup = this.setup.bind(this)
        this.processNextBufferedBatch = this.processNextBufferedBatch.bind(this)
        this.processTimelineChange = this.processTimelineChange.bind(this)
        this.discardUnneededFrames = this.discardUnneededFrames.bind(this)
        this.useS3fs = useS3fs.bind(this)
        this.keyboardControls=this.keyboardControls.bind(this)
    }

    setup(err, data) {
        if (!err) {
            // console.log(data)
            //get hologram info
            var hologramLength = data.Item.LengthInFrames
            var draco = data.Item.DracoEncoding
            var base64 = data.Item.base64;
            var framerate = data.Item.Framerate
            var lengthInBatches = data.Item.BatchNum
            var meshNum = data.Item.MeshNum
            this.setState({
                passDataParams: {
                    hologramLength: hologramLength,
                    draco: draco,
                    base64: base64,
                    framerate: framerate,
                    lengthInBatches: lengthInBatches,
                    meshNum: meshNum
                }
            })
            //worker initialization; reader worker
            var readerWorker = new Worker(process.env.PUBLIC_URL + "./ReaderWorker.js", { type: "module" })
            readerWorker.onmessage = this.receivedReaderWorkerMessage
            var message = {
                type: 'Setup',
                dracoEncoding: draco,
                url: process.env.PUBLIC_URL,
                workerNum: meshNum
            }
            readerWorker.postMessage(message)
            this.setState({
                meshNum: meshNum,
                lengthInBatches: lengthInBatches,
                readerWorker: readerWorker,
                dracoEncoding: draco,
                base64Encoding: base64
            })
            this.setState({
                passDataParams: {
                    hologramLength: hologramLength,
                    draco: draco,
                    base64: base64,
                    framerate: framerate,
                    lengthInBatches: lengthInBatches,
                    meshNum: meshNum
                }
            })
            this.props.setMetadata(hologramLength, framerate, this.props.setHologramLength, this.props.setFramerate)
        } else {
            console.log(err)
        }
    };

    //update each mesh geometry buffer using references
    updateBufferGeometry() {
        var frameToRender = this.state.nextFrame
        for (let i = 0; i < this.state.meshNum; i++) {
            if (!stopPlaying && frameToRender && frameToRender[i] !== undefined && frameToRender[i].textureData !== undefined && frameToRender[i].geometry != null) {
                //get mesh and its reference
                var ref = meshRefs[i].ref
                var mesh = frameToRender[i]
                //get decoder output texture data 
                var texData = new Uint8Array(frameToRender[i].textureData.buf)
                var height = frameToRender[i].textureData.height
                var width = frameToRender[i].textureData.width
                var vertices, triangles, uvs
                if (this.state.dracoEncoding) {
                    vertices = new Float32Array(Object.values(mesh.geometry.attributes[0].array))
                    uvs = new Float32Array(Object.values(mesh.geometry.attributes[1].array))
                    triangles = new Uint16Array(Object.values(mesh.geometry.index.array))
                } else {
                    vertices = mesh.geometry.vertices
                    uvs = new Float32Array(Object.values(mesh.geometry.uvs))
                    triangles = mesh.geometry.faces
                }
                const texture = new THREE.DataTexture(texData, width, height, THREE.RGBAFormat);
                //create new geometry buffer with the data extracted previously
                var newGeometry = new THREE.BufferGeometry()
                newGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
                newGeometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
                newGeometry.setIndex(new THREE.BufferAttribute(triangles, 1));
                //newGeometry.computeVertexNormals()
                var newMat = new THREE.MeshBasicMaterial()
                newMat.side = THREE.DoubleSide
                newMat.map = texture
                //access mesh through reference, replace geometry and texture
                // eslint-disable-next-line
                newGeometry.computeVertexNormals()

                var currGeom=ref.current.geometry
                var currOutGeom
                ref.current.geometry = newGeometry
                if (meshRefs[i].hovered||meshRefs[i].clicked){
                    currOutGeom=meshRefs[i].refOut.current.geometry
                    meshRefs[i].refOut.current.geometry = newGeometry
                }
                ref.current.material = newMat

                texture.dispose()
                newGeometry.dispose()
                newMat.dispose()
                currGeom.dispose()
                if (meshRefs[i].hovered||meshRefs[i].clicked){
                    currOutGeom.dispose()
                }
            }
        }
    }

    keyboardControls(){
        for (let i = 0; i < this.state.meshNum; i++) {
            if (meshRefs[i].clicked){
                var ref = meshRefs[i].ref
                var refOut = meshRefs[i].refOut
                ref.current.rotation.y = this.props.controls.meshRotationY[i]
                refOut.current.rotation.y = ref.current.rotation.y
                ref.current.rotation.y = this.props.controls.meshRotationY[i]
                ref.current.scale.set(this.props.controls.meshScale[i],this.props.controls.meshScale[i],this.props.controls.meshScale[i])
                refOut.current.scale.set(this.props.controls.meshScale[i],this.props.controls.meshScale[i],this.props.controls.meshScale[i])
                ref.current.position.set(this.props.controls.meshPos[i].x,this.props.controls.meshPos[i].y,this.props.controls.meshPos[i].z)
                refOut.current.position.set(this.props.controls.meshPos[i].x,this.props.controls.meshPos[i].y,this.props.controls.meshPos[i].z)
            }
        }
    }
    
    async playFrames() {
        while (true) {
            this.keyboardControls()
            if (frameBuffer.length > 0) {
                if (this.props.playerState.status !== playStatus.PAUSED) {
                    //if we were previously buffering, update the state to playing
                    if (this.props.playerState.status === playStatus.BUFFERING) this.props.updateStatus(playStatus.PLAYING)
                    this.props.incrementTimelinePos(this.props.timelinePos, this.props.setTimelinePos)
                    this.setState({
                        nextFrame: frameBuffer.shift()
                    }, this.updateBufferGeometry)
                }
            } else {
                if (this.state.getBatchData <= this.state.lengthInBatches && !changedTimelinePos && !requestingNext) {
                    console.log("Use s3fs1")
                    remainingFrames = 30
                    requestingNext = true
                    this.setState({
                        getBatchData: this.state.getBatchData + 1
                    }, this.useS3fs(this.props.archiveId, this.newFetchNextBatch, toggleBuffering, this.state.getBatchData, this.state.passDataParams, false))
                }
            }
            await delay(57)
        }
    }





    receivedReaderWorkerMessage(event) {
        if (event.data.type === 'decoderReady') {
            remainingFrames = 30
            requestingNext = true
            console.log("Use s3fs2")
            this.setState({
                fetchingBatch: 2,
                getBatchData: 1
            },
                // this.fetchNextBatch(1)
                this.useS3fs(this.props.archiveId, this.newFetchNextBatch, toggleBuffering, this.state.getBatchData, this.state.passDataParams, false)
            )
        } else
            if (event.data.type === 'Processed Frame') {
                //console.log(event.data)
                var batchStart = event.data.batchStart
                //use batch only if it's still valid
                var batch = this.state.frameQueue
                var geometry
                if (this.state.dracoEncoding) {
                    geometry = getGeometry(event.data.geometry);
                } else {
                    geometry = event.data.geometry
                }
                let textureData = { buf: event.data.textureData, height: event.data.textureHeight, width: event.data.textureWidth }
                let mesh = { geometry: geometry, textureData: textureData };
                if (!batch.hasOwnProperty(event.data.frameBatchPos)) batch[event.data.frameBatchPos] = {};
                (batch[event.data.frameBatchPos])[event.data.meshID] = mesh;
                this.setState({ frameQueue: batch })
                //console.log(event.data.batchID)
                //console.log(remainingFrames)
                if (event.data.decodingJobsLeft === 0) {
                    var frameArray = getSortedFrames(batch)
                    //only start geometry updater at the beginning
                    if (!this.state.loaded) {
                        var refs = []
                        var refsOut = []
                        var baseMeshList = []
                        var meshNum = this.state.meshNum
                        //create build meshes and create references to them
                        for (let i = 0; i < meshNum; i++) {
                            var ref = React.createRef()
                            var refOut = React.createRef()
                            var refObject={
                                "ref":ref,
                                "refOut":refOut,
                                "hovered":false,
                                "clicked":false
                            }
                            refs.push(refObject)
                            if (this.props.setArRef) {
                                // concat the ref to setArRef
                                // eslint-disable-next-line
                                // this.props.setArRef(prev => prev.concat(ref))
                                this.props.setArRef(ref)
                            }
                            var baseMesh = BuildMesh(i, ref,refOut,this.props.amtc,this.props.rmfc,this.props.imic)
                            baseMeshList.push(baseMesh)
                        }
                        frameBuffer = frameArray
                        remainingFrames--
                        meshRefs= refs
                        this.setState({
                            frameQueue: {},
                            loaded: true,
                            currMeshList: baseMeshList,
                        })
                        //this.startBufferTimer();
                        this.playFrames()
                    } else {
                        console.log(remainingFrames)
                        frameBuffer = frameBuffer.concat(frameArray)
                        if (remainingFrames === 1) {
                            requestingNext = false
                            this.setState({
                                frameQueue: {}
                            })
                        } else {
                            remainingFrames--
                            this.setState({
                                frameQueue: {}
                            })
                        }
                    }
                    this.state.onGoingReaderRequests.delete(batchStart)
                }

            }else if (event.data.type === 'Audio Data' && !this.props.isMuted&& this.props.playerState.status !== playStatus.PAUSED){
                //const audioData=event.data.data
                bufferedAudioData.push(new Float32Array(event.data.data))
                if (bufferedAudioData.length>0&&!initializedAudio){
                    feeder.init(1, 44100);
                    feeder.waitUntilReady(function() {
                        feeder.bufferData([
                          bufferedAudioData.shift()
                        ]);
                      
                        // Start playback...
                        feeder.start();
                        // Callback when buffered data runs below feeder.bufferThreshold seconds:
                        feeder.onbufferlow = function() {
                            if (bufferedAudioData.length>0){
                                feeder.bufferData([
                                    bufferedAudioData.shift()
                                ]);
                            }
                        };
                        feeder.onstarved = function() {
                            console.log("audio buffer starved!")
                            if (bufferedAudioData.length>0){
                                feeder.bufferData([
                                    bufferedAudioData.shift()
                                ]);
                            }else{
                                console.log("stopping audio.")
                                feeder.stop()
                                initializedAudio=false
                            }
                        };
                      });
                      initializedAudio=true
                }
                //if (!initializedAudio){this.audioUpdates()}
            }
    }

    processNextBufferedBatch() {
        var currFetchedBatches = this.state.fetchedBatches
        if (currFetchedBatches.hasOwnProperty(this.state.processingBatch)) {
            this.sendBatchToWorker(currFetchedBatches[this.state.processingBatch], this.state.processingBatch)
        }
    }

    sendBatchToWorker(batchData, batchID, timelineChanged, buffering) {
        var posInSecs = (batchID - 1) * BATCH_SIZE
        var batchStart = 0
        if (timelineChanged) {
            toggleBuffering()
            remainingFrames = 30
            changedTimelinePos = false
        }
        if (posInSecs >= BATCH_SIZE) {
            batchStart = Math.floor(posInSecs / BATCH_SIZE) * (this.props.playerState.framerate * BATCH_SIZE)
        }
        var dataToSend;
        if (this.state.base64Encoding) {
            dataToSend = B64.decode(batchData)
        } else {
            dataToSend = batchData
        }
        var message = {
            type: 'Batch',
            batchID: batchID,
            batch: dataToSend,
            timelinePos: this.props.playerState.timelinePos,
            pos: batchStart
        }
        if (this.state.readerWorker) {
            this.state.readerWorker.postMessage(message)
            this.state.onGoingReaderRequests.add(batchID)
            // console.log(`Frame ${batchID} sent for decoding`)
            // if (next) { this.setState({ getBatchData: batchID++ }) }
            // if (next && this.state.requestingNext) {
            //     this.setState({ getBatchData: batchID++ })
            // }
        }

    }



    fetchNextBatch(val) {
        console.log(val)
    }
    // async newFetchNextBatch(archiveID, batchID, index, fsImpl) {
    //     // console.log('Fetching batch ' + archiveID, batchID, index, fsImpl)
    //     try {
    //         var decoder = new TextDecoder("utf-8");
    //         const data = await fsImpl.readFile(`archive/${archiveID}/${batchID}`, { encoding: 'binary' })
    //         var batchData = decoder.decode(data.Body)
    //         if (data) {
    //             this.sendBatchToWorker(batchData, index)
    //         }
    //     }
    //     catch (err) {
    //         console.log('====fetchNextBatch====\n ', err)
    //     }
    // }
    async newFetchNextBatch(data, index, next, buffering) {
        // console.log('Fetching batch ', index, next)

        try {
            if (data) {
                this.sendBatchToWorker(data, index, next, buffering)

            }
            // if (next) { this.setState({ getBatchData: index++ }) }
        }
        catch (err) {
            console.log('====fetchNextBatch====\n ', err)
        }
    }




    //===former place of setup function====
    discardUnneededFrames() {
        var currBuf = this.state.batchBuffer
        var currKeys = Object.keys(currBuf)
        for (let i = 0; i < currKeys.length; i++) {
            let batchID = currKeys[i]
            if (batchID < this.state.playbackBatch) {
                delete currBuf[batchID]
            } else {
                for (let f = 0; f < currBuf[batchID].length; f++) {
                    if (batchID * BATCH_SIZE + f < this.props.timelinePos) {
                        currBuf[batchID].shift()
                    }
                }
                break
            }
        }
        this.setState({
            buildingBuffer: false
        })
    }


    processTimelineChange(newPos) {
        console.log("Use s3fs3")
        requestingNext = true
        changedTimelinePos = true
        remainingFrames = 30
        this.useS3fs(this.props.archiveId, this.newFetchNextBatch, toggleBuffering, newPos, this.state.passDataParams, true)
    }

    componentDidMount() {
        console.log("Holotch archive web player version 1.1")
        const params = {
            TableName: 'StoredHolograms',
            Key:
            {
                HologramID: this.props.archiveId
            }
        }
        dynamodb.get(params, this.setup)
    }

    componentDidUpdate(prevProps, prevState) {
        //if the user changed timeline position
        if (Math.abs(this.props.playerState.timelinePos - prevProps.playerState.timelinePos) > 1) {
            var newPos = Math.floor((this.props.playerState.timelinePos / this.props.playerState.framerate) / BATCH_SIZE) + 1
            //invalidate all previous requests
            this.state.onGoingReaderRequests.clear()
            this.setState({
                getBatchData: newPos + 1,
                playbackBatch: newPos,
                processingBatch: newPos,
                batchBuffer: {},
                framesFromDecoder: {},
                fetchedBatches: {},
                buildingBuffer: true,
                bufferCounter: 3
            },
                this.processTimelineChange(newPos)
            )
            console.log('Timeline changed to: ' + this.props.playerState.timelinePos)

        }
        if (prevProps.timelinePos !== this.props.timelinePos) {
            if (this.state.loaded) {
                this.props.pageLoaderCheck(this.state.loaded, this.props.setCheckLoader)
                // this.useS3fs(this.props.archiveId, this.newFetchNextBatch, this.state.playbackBatch, this.state.passDataParams)
            }
        };
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }
    //return null;
    render() {
        if (this.state.loaded) {
            return this.state.currMeshList
        } else {
            return null
        }
    }
};

export default ReadArchive